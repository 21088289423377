import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, ModalHandler, SupportoTecnico, Title } from "components/misc";
import { dateHelper, learningService, networkErrorHelper } from "libs";
import { Button, Icon } from "design-react-kit";
import { ROUTES } from "const";
import { rc30ExamService } from "libs/api/rc30ExamService";
import { MRadioButton } from "components/forms";

class RC30ExamSubject extends Component {

    DAYS_DEADLINE = 1;
    HOUR_DEADLINE = "10:00";

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: '',
        examSubject: false,
        idSelected: 0,
        showValidation: false
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        this.setState({ loading: true, loadingError: false, rCode });

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, rCode });

                rc30ExamService.myExamSubject(rCode)
                    .then(({ data }) => {

                        const { examSubject } = data.payload;
                        this.setState({
                            loading: false,
                            examSubject,
                            idSelected: examSubject.idSelected,
                            loadingError: false
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }

                        this.setState({ rCode, loading: false, loadingError: true });
                    });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    onChangeSubject = (id, value) => {
        this.setState({ idSelected: value })
    }

    confirmSubject = (e, value) => {
        ModalHandler.show(
            value,
            "Conferma Argomento",
            "Vuoi confermare l'argomento selezionato? La scelta non potrà più essere modificata.",
            null,
            this.onConfirm
        );
    }; //deleteRecord

    onConfirm = (value) => {
        this.setState({
            loading: true,
            loadingError: false,
            showValidation: false
        });

        const { idSelected, rCode } = this.state;
        if (!idSelected) {
            this.setState({ loading: false, showValidation: true });
            return true;
        }

        rc30ExamService
            .confirmExamSubject(rCode, idSelected)
            .then((data) => {
                this.setState({
                    loading: false,
                    loadingError: false,
                });

                window.location.reload();

            })
            .catch((errors) => {
                console.log(errors);
                networkErrorHelper.notify(errors);
            });
    };

    canEdit = (dateExam) => {

        // la scelta dell'argomento è abilitata 
        // entro il giorno precedente l'esame alle ore 12:00
        const dayLeft = dateHelper.dayLeft(dateExam);
        if (dayLeft > this.DAYS_DEADLINE) {
            return true;
        } else if (dayLeft === this.DAYS_DEADLINE) {
            const minuteLeft = dateHelper.minuteLeft(this.HOUR_DEADLINE);
            if (minuteLeft > 0) {
                return true;
            }
        }

        return false;
    }

    renderEdit(examSubject, userCourse) {

        const { showValidation } = this.state;

        const isAB = (userCourse.pluginConfig.competitionClass === 'AB24' || userCourse.pluginConfig.competitionClass === 'AB25') ? true : false;

        const options = [
            { value: examSubject.id1, label: examSubject.title1 },
            { value: examSubject.id2, label: examSubject.title2 },
            { value: examSubject.id3, label: examSubject.title3 },
        ];

        const payloadRadio = { options }

        // 30 CFU/CFA (ex art. 13 DPCM 4 agosto 2023)
        // del percorso abilitante 30 CFU/CFA (ex art. 13 DPCM 4 agosto 2023)

        return (<div className="p-5">
            {(isAB) ?
                <><p>Gli argomenti individuati dalla Commissione per la lezione simulata del percorso abilitante sono i seguenti:</p>
                </> : <><p>Gli argomenti scelti dalla Commissione per la “lezione simulata”, facente parte della prova finale sono i seguenti:</p>
                </>}
            <ul>
                <MRadioButton
                    onChange={this.onChangeSubject}
                    id="idSubject"
                    payload={payloadRadio}
                    value={this.state.idSelected}
                ></MRadioButton>
            </ul>
            {showValidation && <BoxDanger>Selezionare un argomento</BoxDanger>}
            <p>{(isAB) ?
                <span>Di questi argomenti potrà sceglierne liberamente uno e su questo potrà svolgere una lezione simulata da esporre in italiano utilizzando
                    esempi specifici e illustrazioni pratiche concernenti direttamente
                    la didattica dell'inglese come lingua straniera nella scuola. La invitiamo a selezionare l'argomento scelto e
                </span> : <span>Di questi argomenti potrà sceglierne liberamente uno e su questo potrà svolgere una lezione simulata. La invitiamo a selezionare l'argomento scelto e
                </span>}
                Premere il tasto <b>conferma argomento</b>
            </p>
            <Button onClick={this.confirmSubject} className="btn btn-primary">Conferma argomento</Button>
        </div>)
    }

    renderRead(examSubject) {

        return (
            <>
                <h5>Argomento scelto per la lezione simulata:</h5>
                <p><b>{examSubject.titleSelected}</b></p> </>
        );
    }

    renderDeadline(examSubject) {

        return (
            <><BoxDanger className="mt-4">
                Non è più possibile scegliere l'argomento della lezione simulata.
                Gli argomenti assegnati dalla commissione sono i seguenti:
                <ul>
                    <li>{examSubject.title1}</li>
                    <li>{examSubject.title2}</li>
                    <li>{examSubject.title3}</li>
                </ul>
                <p>Potrà effettuare la scelta il giorno della prova finale notificandolo alla commissione attraverso il personale di segreteria presente in sede </p>
            </BoxDanger></>
        );
    }

    render() {

        const { loading, loadingError, rCode, examSubject, userCourse } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError || !examSubject) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        return (<>  <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Argomento Lezione Simulata</Title>
            <hr />
            {(examSubject.idSelected > 0) ? <>{this.renderRead(examSubject)}</>
                : <>
                    {this.canEdit(examSubject.dateExam) ?
                        <>{this.renderEdit(examSubject, userCourse)}</>
                        : <>{this.renderDeadline(examSubject, userCourse)}</>

                    }
                </>
            }

        </>)
    }
}

export default withRouter(RC30ExamSubject);