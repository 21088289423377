import React, { Component } from "react";
import { MnemoLoading, BoxDanger, Title } from "components/misc";
import { networkErrorHelper, dateHelper } from "libs";

import { MTable } from "components/table";
import { Container } from "design-react-kit";

import { revisionService } from "libs/api/revisionService";
import { withRouter } from "react-router-dom";
import { ROUTES } from "const";

class ReviewPendingAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rndKey: 0,
      payload: [],
      idUserRevisor: this.props.idUserRevisor,
    };
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "fullName",
        Header: "Nome e Cognome",
        accessor: "fullName",
      },
      {
        id: "registrationCode",
        Header: "Codice Registrazione",
        accessor: "registrationCode",
      },
      {
        id: "dateReview",
        Header: "Data Revisione",
        accessor: (row) => {
          return row.dateReview ? dateHelper.toITDate(row.dateReview) : "";
        },
      },
      {
        id: "competitionClass",
        Header: "Classe Concorso",
        accessor: "competitionClass",
      },
      {
        id: "version",
        Header: "Versione UDA",
        accessor: "version",
      },
      {
        id: "revisionerFullName",
        Header: "Revisore",
        accessor: "revisionerFullName"
      },
      {
        id: "revisionNote",
        Header: "Note",
        accessor: "revisionNote"
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.EXAMREVIEW_FORM + "/" + value;
        },
      },
    ],
  };

  //--- ---

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    this.setState({ loading: true });

    revisionService
      .listAllRevisionedPendingExam()
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.examReview).forEach(([key, val]) => {
          items.push(val);
        });

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }

        this.setState({ loading: false });
      });
  }

  //--- ---

  render() {
    const { loading } = this.state;

    return (
      <>
        <Title>Revisioni non Idonee - 30CFU</Title>

        <>
          <hr />
          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payload}
                  tableConfig={this.tableConfig}
                />
              </Container>
            )}

            {this.state.payload.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                <div className="small text-info">
                  Non ci sono dati da mostrare in database.
                </div>
              </BoxDanger>
            )}
          </section>
        </>
      </>
    );
  }
}

export default withRouter(ReviewPendingAdmin);
