import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { withRouter, NavLink } from "react-router-dom";

import { Navbar, LinkList, NavItem } from "design-react-kit";
import { learningService, networkErrorHelper } from "libs";
import { TimeWatcher } from "components/misc";
import { ROUTES } from "const";

export class SidebarLearning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myCourse: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadCourse(this.props.rCode);
  }

  loadCourse(rCode) {
    learningService
      .courseDashboard(rCode)
      .then(({ data }) => {
        this.setState({
          myCourse: data.payload.userCourse,
          course: data.payload.course,
          loading: false,
        });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({ loadingError: true, loading: false });
        }
      });
  }

  getRightURL = (item) => {
    let url = `${ROUTES.LEARNING_LESSON}/${item.id}/${
      this.props.rCode ? this.props.rCode : ""
    }${this.props.idModule ? "/" + this.props.idModule : ""}`;

    if (item.childResourceType === "quiz") {
      url =
        ROUTES.LEARNING_QUIZ_MAIN +
        "/" +
        item.childResourceId +
        "/" +
        item.idQuiz +
        "/" +
        this.props.rCode;
    }
    return url;
  }; //getRightURL

  render() {
    const { course, loading } = this.state;
    if (loading) return <></>;

    const showTimer =
      course && course.metadata && course.metadata.showTimer ? true : false;
    const lessonsIndex = this.props.lessonsIndex;

    return (
      <>
        <div className="learning-sidebar navbar-wrapper">
          <Navbar
            className="it-navscroll-wrapper it-top-navscroll"
            expand="lg"
            tag="nav">
            <div className="menu-wrapper">
              {showTimer && (
                <TimeWatcher
                  rCode={this.props.rCode}
                  metadata={course.metadata}
                />
              )}

              <LinkList
                header={<h3 className="no_toc">ARGOMENTI</h3>}
                tag="div"
                wrapperClassName="menu-link-list">
                {lessonsIndex
                  .filter((item) => {
                    return (
                      item.sidebarIndex === null || item.sidebarIndex === ""
                    );
                  })
                  .map((item, i) =>
                    item.maintenance === "" || item.maintenance === null ? (
                      <NavItem key={`lessonsIndex-${i}`} tag="li">
                        <NavLink
                          key={`lessonsIndex-a-${i}`}
                          className="nav-link"
                          to={this.getRightURL(item)}>
                          {ReactHtmlParser(item.label)}
                        </NavLink>
                      </NavItem>
                    ) : (
                      <NavItem key={`lessonsIndex-${i}`} tag="li">
                        <div className="nav-link text-info">
                          <b>{ReactHtmlParser(item.label)}</b>
                          {item.maintenance && (
                            <>
                              <br />
                              <span className="text-danger">
                                {item.maintenance}
                              </span>
                            </>
                          )}
                        </div>
                      </NavItem>
                    )
                  )}

                {lessonsIndex.filter((item) => {
                  return item.sidebarIndex === "common";
                }).length > 0 && (
                  <LinkList
                    header={<h3 className="no_toc">MATERIALE</h3>}
                    tag="div"
                    wrapperClassName="menu-link-list">
                    {lessonsIndex
                      .filter((item) => {
                        return item.sidebarIndex === "common";
                      })
                      .map((item, i) =>
                        item.maintenance === "" || item.maintenance === null ? (
                          <NavItem key={`lessonsIndex-c-${i}`} tag="li">
                            <NavLink
                              key={`lessonsIndex-ac-${i}`}
                              className="nav-link"
                              to={this.getRightURL(item)}>
                              {ReactHtmlParser(item.label)}
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavItem key={`lessonsIndex-c-${i}`} tag="li">
                            <div className="nav-link text-info">
                              <b>{ReactHtmlParser(item.label)}</b>
                              {item.maintenance && (
                                <>
                                  <br />
                                  <span className="text-danger">
                                    {item.maintenance}
                                  </span>
                                </>
                              )}
                            </div>
                          </NavItem>
                        )
                      )}
                  </LinkList>
                )}

                {this.props.rCode && (
                  <>
                    <NavItem key={`lessonsIndex-back2course`} tag="li">
                      <NavLink
                        key={`lessonsIndex-a-back2course`}
                        className="nav-link text-danger"
                        to={`${ROUTES.LEARNING_MAIN}/${this.props.rCode}`}>
                        torna all'indice del corso
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </LinkList>
            </div>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withRouter(SidebarLearning);
