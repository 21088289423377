import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MTextArea,
  MSelect,
} from "components/forms";

import { payloadBuilder, networkErrorHelper, examService } from "libs";

import dayjs from "dayjs";

import ReactHtmlParser from "react-html-parser";

import { Card, CardBody } from "design-react-kit";
import { EXAMVOTE_30CFU } from "const";
import { revisionService } from "libs/api/revisionService";
import { MnemoCallout, MnemoLoading } from "components/misc";
import { rc30ExamService } from "libs/api/rc30ExamService";
import { getCurrentUser } from "store/actions";
import { t } from "i18next";

export class AdminExamReview30Cfu extends ValidatedForm {
  ERROR_MESSAGES = {};

  emptyFields = {
    examId: parseInt(this.props.idExamReview) || 0,
    vote: "",
    revisionNote: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      idExamReview: this.props.idExamReview,
      examQuestions: [],
      exam: {},
      examRevision: {},
      validationSummary: "",
      loader: false,
    };

    this.handleVote = this.handleVote.bind(this);
  }

  validation = {
    vote: (value) => value,
  };

  FIELDS_GROUP = [
    [
      {
        id: "vote",
        field: "vote",
        label: "Valutazione a cura del Revisore",
        component: MSelect,
        payload: { options: EXAMVOTE_30CFU },
        className: "col-md-12",
        onChange: (name, value) => {
          this.handleVote(value);
        },
      },
    ],
    [
      {
        id: "revisionNote",
        field: "revisionNote",
        label: "Note revisione",
        component: MTextArea,
        className: `col-md-12 d-none`,
        rows: 10,
      },
    ],
  ];

  //--- ---

  componentDidMount() {
    this.loadRemote();
  }

  handleVote(vote) {
    this.setState({
      vote: vote,
      revisionNote: "",
    });

    const revisionNoteTextArea = document.getElementById("revisionNote");
    revisionNoteTextArea.value = "";

    vote === "non idoneo"
      ? revisionNoteTextArea.parentElement.classList.remove("d-none")
      : revisionNoteTextArea.parentElement.classList.add("d-none");

    if (vote === "") {
      const voteSelect = document.getElementById("vote");
      if (voteSelect && voteSelect.options.length > 0) {
        voteSelect.selectedIndex = 0;
        this.setState({
          vote: voteSelect.options[0].value,
        });
      }
    }
  } //handleVote

  loadRemoteSubject(rCode) {
    this.setState({ loading: true, loadingError: false, rCode });

    // load paylods to fill the select options
    rc30ExamService
      .myExamSubjectUDA(rCode)
      .then(({ data }) => {
        const { examSubject } = data.payload;

        this.setState({
          titleSelected: examSubject.titleSelected,
          loading: false,
        });
      })
      .catch((errors) => {
        console.log(errors);
        //networkErrorHelper.notify(errors);
      });
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idExamReview) <= 0) {
      return false;
    }

    examService
      .getExamReviewByIdExam(parseInt(this.props.idExamReview))
      .then(({ data }) => {
        this.setState({
          examQuestions: data.payload.examQuestion,
          exam: data.payload.exam,
          examRevision: data.payload.examRevision,
          loading: false,
        });

        /*const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.quizReview
                );

                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading: false
                };

                this.setState(newState);*/
        this.loadRemoteSubject(data.payload.exam.registrationCode);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

  onCancel = () => {
    this.handleVote("");
  }; //onCancel

  onBackPage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.back();
  };

  onSubmit = () => {
    const payload = payloadBuilder(this.state);
    payload.vote = this.state.vote;
    payload.revisionProblem = false;

    if (payload.vote === "non idoneo") {
      payload.revisionProblem = true;
    }

    let validationSummary = "";
    let isValid = true;
    if (payload.vote === "") {
      validationSummary += "<li>Inserire la Valutazione;</li>";
      isValid = false;
    }

    if (payload.vote === "non idoneo" && payload.revisionNote === "") {
      validationSummary += "<li>Inserire le Note di revisione;</li>";
      isValid = false;
    }

    this.setState({ validationSummary: validationSummary });

    if (isValid === true) {
      this.saveRemote(payload);
    }
  };

  saveRemote = (payload) => {
    this.setState({ loader: true });

    if (!this.state.examRevision) {
      payload.revisionProgress = 1;
      payload.date = dayjs().format("YYYY-MM-DD H:mm:s");
      revisionService
        .insertExamReview30Cfu(payload)
        .then((result) => {
          this.setState({ loader: false });
          this.onBackPage();
        })
        .catch((errors) => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    } else {
      payload.id = this.state.examRevision.id;
      payload.revisionProgress =
        parseInt(this.state.examRevision.revisionProgress) + 1;
      payload.date = dayjs().format("YYYY-MM-DD H:mm:s");
      revisionService
        .updateExamReview30Cfu(payload)
        .then((result) => {
          this.setState({ loader: false });
          this.onBackPage();
        })
        .catch((errors) => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }
  };

  render() {
    let { formActive, defaultValues, examQuestions, exam, validationSummary } =
      this.state;

    const { code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    const user = getCurrentUser();

    //--- ---

    if (this.state.loader) return <MnemoLoading />;

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <div className="row">
              {("revisioned", "revisionPending").includes(exam.status) && (
                <div className="col-12 mb-4">
                  <MnemoCallout
                    className="mnemo-callout-info w-100 mb-4"
                    title="Note di revisione:">
                    <p>
                      <i>
                        {ReactHtmlParser(this.state.examRevision.revisionNote)}
                      </i>
                    </p>
                  </MnemoCallout>
                  <hr />
                </div>
              )}
              {(examQuestions || []).map((question, index) => {
                return (
                  <MnemoCallout
                    key={index}
                    className="mnemo-callout-info w-100 mb-4"
                    title={question.question}>
                    <p>
                      {question.field === "examSubject" ? (
                        <>{this.state.titleSelected}</>
                      ) : (
                        <>{question.answer}</>
                      )}
                    </p>
                  </MnemoCallout>
                );
              })}
            </div>

            {exam.status === "confirmed" && (
              <>
                <hr />

                <form className="user mt-5" id="examreviewform">
                  {code}
                </form>

                {user.roles === "revisor" && (
                  <>
                    <div className="w-100 mt-4 mb-4">
                      {validationSummary && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {ReactHtmlParser(validationSummary)}
                        </div>
                      )}
                    </div>

                    {(this.state.loader === false) && <div className="mt-4">
                      <FormEnabler
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        isFormActive={formActive}
                      />
                    </div>}
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}
