const { MButton2, MInput, MLabel } = require("components/forms");

export class FormTitleAConfigurator {

    static extCode = {
        LaureaScienzeFormazionePrimariaVO: "LAU_FORPRIM_VO",
        LaureaScienzeFormazionePrimaria249: "LAU_FORPRIM_249",
        DiplomaMagistraleAA20012002: "DIPMAG",
        SpecializzazioneSostegno: "CT",
        Pas: "PAS",
        cfu60: "ALTRO", //TODO: chiedere un codice a MArco Aveta e sostituire
        Ssis: "SSIS",
        COBASLID: "COBASLID",
        DiplomaAccademicoAFAM: "AFAM II",
        DiplomaDidatticaMusica: "DIAMUSI",
        ConcorsoTitoliEsami: "CONC",
        SessioniRiservateAbilitazioni: "CONC",
        Tfa: "TFA",
        TitoloEsteroRiconosciuto: "TITPR_EST",
        TitoloEsteroPresentataDomandaRiconoscimento: "EST_SEC_2019"
    };

    static forms = [
        {
            template: 'LaureaScienzeFormazionePrimariaVO',
            label: 'Laurea in Scienze della Formazione Primaria Legge 341/1990 "vecchio ordinamento"',
        },
        {
            template: 'LaureaScienzeFormazionePrimaria249',
            label: 'Laurea in Scienze della Formazione Primaria DM 249/2010 classe LM-85 bis',
        },
        {
            template: 'DiplomaMagistraleAA20012002',
            label: `Diploma magistrale conseguito entro l'anno accademico 2001/2002`,
        },
        {
            template: 'SpecializzazioneSostegno',
            label: `Specializzazione sul sostegno`,
        },
        {
            template: 'Tfa',
            label: `Tirocinio Formativo Attivo (non selezionare in caso di specializzazione sul sostegno)`,
        },
        {
            template: 'Pas',
            label: `PAS (Percorso Abilitante Speciale)`,
        },
        {
            template: 'cfu60',
            label: `Valida Abilitazione 60 CFU o 30 CFU ai sensi del DPCM 4 agosto 2023`,
        },
        {
            template: 'Ssis',
            label: `SSIS (Scuola di Specializzazione per l'Insegnamento Secondario)`,
        },
        {
            template: 'COBASLID',
            label: `COBASLID (per la scuola secondaria)`,
        },
        {
            template: 'DiplomaAccademicoAFAM',
            label: `Diploma accademico di II livello rilasciato da istituzione AFAM per l'insegnamento dell'Educazione musicale o dello strumento`,
        },
        {
            template: 'DiplomaDidatticaMusica',
            label: `Diploma di Didattica della Musica (Legge 268/2002)`,
        },
        {
            template: 'ConcorsoTitoliEsami',
            label: `Concorso per titoli ed esami`,
        },
        {
            template: 'SessioniRiservateAbilitazioni',
            label: `Sessioni riservate di abilitazione`,
        },       
        {
            template: 'TitoloEsteroRiconosciuto',
            label: `Titolo accademico conseguito all'estero e riconosciuto abilitante all'insegnamento con apposito decreto del MIUR`,
        },
        {
            template: 'TitoloEsteroPresentataDomandaRiconoscimento',
            label: `Titolo accademico di accesso conseguito all'estero per il quale è stata presentata la relativa domanda di riconoscimento alla Direzione Generale per gli ordinamenti scolastici e la valutazione del sistema nazionale di istruzione`,
        },
    ];

    static LaureaScienzeFormazionePrimariaVO = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", 
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Indirizzo (solo per laurea conseguita ai sensi del DM 26 maggio 1998)",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaScienzeFormazionePrimariaVO = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
    };

    static LaureaScienzeFormazionePrimaria249 = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Indirizzo (solo per laurea conseguita ai sensi del DM 26 maggio 1998)",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo ",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaScienzeFormazionePrimaria249 = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
    };

    static DiplomaMagistraleAA20012002 = [
        [{
            id: "searchSchool",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Istituto",
             className: "col-md-12 mb-3",            
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso la scuola",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "province",
                field: "province",
                label: "Provincia",
                component: MInput,
                type: "text",
                className: "col-md-4",
                readOnly: true,
            },
            {
                id: "address",
                field: "address",
                label: "Via",
                component: MInput,
                type: "text",
                className: "col-md-8",
                readOnly: true,
            },
        ],
        [
            {
                id: "durata",
                field: "durata",
                label: "Durata",
                component: MInput,
                type: "number",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_DiplomaMagistraleAA20012002 = {
        istitution: "",
        city: "",
        province: "",
        address: "",
        durata: "",      
        dataConseguimento: "",
    };

    static SpecializzazioneSostegno = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Ordine di scuola",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA (N.B. l'anno accademico di riferimento della specializzazione sul sostegno potrebbe essere quello di emanazione del bando e non quello successivo di effettiva frequenza del corso.)",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "ciclo",
                field: "ciclo",
                label: "Ciclo",
                component: MInput,
                className: "col-md-2 pt-3",
            },
        ]
    ]
    
    static dv_SpecializzazioneSostegno = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
        ciclo:""
    };

    static Pas = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Classe di abilitazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "ciclo",
                field: "ciclo",
                label: "Ciclo",
                component: MInput,
                className: "col-md-2 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static dv_Pas = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
        ciclo: "",
    };

    static cfu60 = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Classe di abilitazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [           
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
        ],
    ]

    static dv_cfu60 = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",       
    };

    static Ssis = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Classe di abilitazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "ciclo",
                field: "ciclo",
                label: "Ciclo",
                component: MInput,
                className: "col-md-2 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static dv_Ssis = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
        ciclo: "",
    };

    static COBASLID = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Denominazione titolo",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static dv_COBASLID = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
    };

    static DiplomaAccademicoAFAM = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Denominazione titolo",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static dv_DiplomaAccademicoAFAM = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
    };

    static DiplomaDidatticaMusica = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Denominazione titolo",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static  dv_DiplomaDidatticaMusica = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
    };

    static ConcorsoTitoliEsami = [
        [
            {
                id: "description",
                field: "description",
                label: "Riferimento concorso",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [{
            id: "ufficioScolastico",
            field: "ufficioScolastico",
            label: "Ufficio Scolastico Regionale/Provinciale di competenza",
            component: MInput,
            type: "text",
            className: "col-md-12",
        }],
        [
        {
            id: "dataConseguimento",
            field: "dataConseguimento",
            label: "Data concorso",
            component: MInput,
            type: "date",
            className: "col-md-4 pt-3",
        }
        ],[{
            field: "attachment",  
            label:"(Per i titoli di abilitazione conseguiti a seguito di concorso si prega di inserire idonea certificazione)",
            component: MLabel,
            className: "col-md-12 pt-3"
        }]
    ]

    static dv_ConcorsoTitoliEsami = {
        description: "",
        dataConseguimento: "",
        ufficioScolastico: "",
    };

    static SessioniRiservateAbilitazioni = [
        [
            {
                id: "description",
                field: "description",
                label: "Denominazione titolo",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [{
            id: "istitution",
            field: "istitution",
            label: "Presso",
            component: MInput,
            type: "text",
            className: "col-md-12",
        }],
        [{
            id: "ufficioScolastico",
            field: "ufficioScolastico",
            label: "Ufficio Scolastico Regionale/Provinciale di competenza",
            component: MInput,
            type: "text",
            className: "col-md-12",
        }],
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data concorso",
                component: MInput,
                type: "date",
                className: "col-md-4 pt-3",
            },
        ],[{
            field: "attachment",  
            label:"(Per i titoli di abilitazione conseguiti a seguito di concorso si prega di inserire idonea certificazione)",
            component: MLabel,
            className: "col-md-12 pt-3"
        }]
    ]

    static dv_SessioniRiservateAbilitazioni = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        ufficioScolastico: "",
    };

    static Tfa = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Classe di abilitazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "ciclo",
                field: "ciclo",
                label: "Ciclo",
                component: MInput,
                className: "col-md-2 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-5 pt-3",
            },
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-5 pt-3",
            },
        ],
    ]

    static dv_Tfa = {
        description: "",
        istitution: "",
        city: "",
        aa: "",
        dataConseguimento: "",
        ciclo: "",
    };


    static TitoloEsteroRiconosciuto = [
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso l'Università",
                component: MInput,
                type: "text",
                className: "col-md-12",
            }
        ],
        [
            {
                id: "nation",
                field: "nation",
                label: "Nazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            }
        ],
        [
            {
                id: "riconoscimentoMIUR",
                field: "riconoscimentoMIUR",
                label: "Estremi Decreto MIUR di riconoscimento",
                component: MInput,
                type: "text",
                className: "col-md-10",
            },
            {
                id: "dataDecreto",
                field: "dataDecreto",
                label: "Data",
                component: MInput,
                type: "date",
                className: "col-md-2 pt-3",
            },
        ],
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data concorso",
                component: MInput,
                type: "date",
                className: "col-md-4 pt-3",
            },
        ]
    ]

    static dv_TitoloEsteroRiconosciuto = {
        istitution: "",
        nation: "",
        aa: "",
        dataConseguimento: "",
        riconoscimentoMIUR: "",
        dataDecreto: "",
    };

    static TitoloEsteroPresentataDomandaRiconoscimento = [
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-4 pt-3",
            },
        ],[
            {
                id: "istitution",
                field: "istitution",
                label: "Presso l'Università",
                component: MInput,
                type: "text",
                className: "col-md-12",
            }
        ],
        [
            {
                id: "nation",
                field: "nation",
                label: "Nazione",
                component: MInput,
                type: "text",
                className: "col-md-12",
            }
        ],
        [
            {
                id: "dataPresentazioneDomanda",
                field: "dataPresentazioneDomanda",
                label: "Data di presentazione della domanda di riconoscimento",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
            {
                id: "numProtocollo",
                field: "numProtocollo",
                label: "Prot. n.",
                component: MInput,
                className: "col-md-6 pt-3",
            }
        ]       
    ]

    static dv_TitoloEsteroPresentataDomandaRiconoscimento = {       
        istitution: "",
        aa: "",
        dataConseguimento: "",              
        dataPresentazioneDomanda: "",
        numProtocollo: "",
        nation: ""
    };

    static getDefaultValues(template) {

        return this['dv_' + template];
    }

    static getFields(template, onSelectedUniversity, onSelectedSchool) {

        const fieldGroup = this[template];

        if (fieldGroup) {
            if (fieldGroup[0] && fieldGroup[0][0].id === 'searchUniversity') {
                fieldGroup[0][0].onClick = onSelectedUniversity;
            } else if (fieldGroup[0] && fieldGroup[0][0].id === 'searchSchool') {              
                fieldGroup[0][0].onClick = onSelectedSchool;
            }
        } else {
            console.error(template + ' not found');
        }
        return fieldGroup
    }

    static getExtCode(template) {

        return this.extCode[template];


    }
}

