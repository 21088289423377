import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ROUTES } from "const";

import ProtectedRoute from "./ProtectedRoute";
import { StudentPage as SP } from "components/layout";
import NotFoundPage from "views/NotFoundPage";
import Unauthorized from "views/Unauthorized";

import {
    PromoFamily,
    PromoFriend,
    PromoIstitute,
    PromoFreeCourse,
    Promo50,
    PromoTablet,
} from "views/public/promo";

import {
    PasswordResetToken,
    Page,
    PageContact,
    PageWhoAre,
    CourseBrowser,
    CourseBrowserMUPA,
    CourseBrowserPLUA,
    CourseLandingPage,
    CourseFullLayoutPage,
    CourseLandingPageIFrame,
    Login,
    Registration,
    Landing,
    PasswordReset,
    PageSupportoTecnico,
    CourseBrowserSSD,
    PageContactInfo,
    PageGsuiteDetails,
    CourseLandingPageFullLayout
} from "views/public";

import {
    EnrollmentPending,
    Enrollment,
    EnrollmentDetail,
} from "views/enrollment";
import { CardPaymentKO, CardPaymentOK, CardPaymentNotify } from "views/payment";

import {
    C24ExamLive,
    C24ExamLiveReservation, C24ExamWritten,
    C24ExamWrittenOnsiteReservation,
    C24ExamWrittenReservation, ExamDone,
    ExamWrittenReservation, PerfezionamentoExamFinalOnsiteReservation,
    PerfezionamentoExamLive, PerfezionamentoExamLiveReservation,
    PerfezionamentoExamWritten
} from 'views/exams';

import {
    MyDocument, MyProfile, MyQualifications,
    Confirm, MyInvoiceData, Dashboard, MyQuizReview
} from 'views/my';

import {
    CourseDashboard, CourseInvoices,
    CourseTickets, CourseTicketDetail,
    CoursePayments, CourseList,
    CourseDocs, CourseTutor, CourseProvider,
    CourseIstruction, CourseManifesto, C24CertificationRequest,
    CourseSelfDeclaration, CourseTitleRequest, CoursePaymentCD, CourseDetail,
    CourseTitleSelfDeclaration, CourseCFUSelfDeclaration, CourseBookingClass,
} from 'views/course';

import {
    CourseMain, Lesson, FinalPaper,
    LessonLiveDetail, ShowFile
} from 'views/learning';
import { QuizMain, QuizFeedbackFinal } from 'views/quiz';
import Examples from 'views/admin/example/Examples';

import { Agenda } from 'views/agenda/Agenda';
import { Career } from 'views/career/Career';

import {
    CourseTypeManager, CourseManager, CourseForm, CourseTypeForm,
    LessonForm, QuizForm
} from 'views/admin/course';
import { AdminUser, SyncIn } from 'views/admin/user';

import { MobileConfirm } from 'views/tools/MobileConfirm';
import { QualificationEvaluation, TitleDelivery, TitleDeliveryESB, QualificationMap, PageContactRequest } from 'views/public/services';
import { RepoQuiz, RepoQuizForm } from 'views/admin/repoquiz';
import { Promotional, PromotionalDetail } from 'views/admin/promotional';
import { Siteconfig, SiteconfigDetail } from 'views/admin/siteconfig';

import { MUCoursePayments, MUCourseDocs, MUCourseTitleRequest, MUC24CertificationRequest, MUCoursePaymentRequest } from 'components/plugin/mu';
import { ICDLCertification, ICDLLesson, ICDLExamReservation } from 'components/plugin/icdl';
import { ESBLesson, ESBExamReservation, AssistanceOnlineMNE, AssistanceTelephoneMNE, MNESimulationExam } from "components/plugin/esb";
import { M24Lesson } from "components/plugin/m24";
import { RC30ExamReservation, RC30ExamSubject, RC30CertificationRequest } from "components/plugin/rc30";
import GOMPPaymentNotify from "views/payment/GOMPPaymentNotify";
import { BlogArticle, BlogHome } from "views/public/blog";

import { ReviewCurrent } from 'views/admin/quizreview';
import ReviewHistory from "views/admin/quizreview/ReviewHistory";

import {
    BlogCmsCategory,
    BlogCmsCategoryForm,
    BlogCmsArticle,
    BlogCmsArticleForm,
    PageCmsList,
    PageCmsForm,
    LibraryCmsCategory,
    LibraryCmsCategoryForm,
    LibraryCmsAuthor,
    LibraryCmsAuthorForm,
    LibraryCmsBook,
    LibraryCmsBookForm,
    ANSCmsMain,
    AssetsAudioMain,
    AssetsAudioMainForm,
    AssetsAudioFileMain,
    AssetsAudioFileMainForm,
    LessonLiveScheduleAnalytics,
    LessonLiveScheduleProcess,
    SurveyCmsForm
} from "views/admin/cms";

import { PageRule } from "views/public/PageRule";
import { PageSolidarity } from "views/public/solidarity/PageSolidarity";
import { PageSolidarity2 } from "views/public/solidarity/PageSolidarity2";
import { PageLeoniSicani } from "views/public/solidarity/PageLeoniSicani";

import { PageUnicef } from "views/public/solidarity/PageUnicef";

import {
    Concessions,
    BenefitsC24,
    BenefitsM24,
    ReferenceLegislation,
} from "views/public/24cfu";
import {
    AccessTitleATA,
    BenefitsClil,
    TeacherCard,
    NewsCancel,
    EnglishCertification,
    PerfezionamentoPduPlu,
    MasterMUPAMSLU
} from "views/public/pages";
import { Doctorate } from "views/public/Doctorate";

// biblioteca
import { Biblioteca } from "views/biblioteca";
import CategoryBooks from "views/biblioteca/category/Category";
import Book from "views/biblioteca/book/Book";
import { Authors } from "views/biblioteca/authors/Authors";
import Author from "views/biblioteca/author/Author";
import { ListBooks } from "views/biblioteca/books/Books";
import { BooksCategories } from "views/biblioteca/categories/Categories";
import BookViewer from "views/biblioteca/book-viewer/BookViewer";
import { Wishlist } from "views/biblioteca/wishlist/Wishlist";

// sedi d'esame
import { PageSediEsami } from "views/public/SediEsami";

// Corsi di laurea
import { PageCorsiDiLaurea } from "views/public/PageCorsiDiLaurea";

// 5cfu adesione form
import { Form5CfuView } from "views/public/5cfu";
import { Form30CfuUnicamillusView } from "views/public/30cfu/request30cfuunicamillus";
import Review from "views/admin/quizreview/Review";
import CourseTutorMNE from "components/plugin/esb/CourseTutorMNE";
import { Form60CfuView } from "views/public/60cfu";
import { ContactRequest } from "views/admin/contactrequest";
import { Form60cfuEmbedded } from "views/public/60cfu/form60cfuEmbedded";
import { UCSUCertificationRequest, UCSULesson } from "components/plugin/ucsu";
import { RCSULesson } from "components/plugin/rcsu";
import LessonLiveSchedule from "views/admin/cms/LessonLiveSchedule";
import LessonLiveScheduleForm from "views/admin/cms/LessonLiveScheduleForm";
import LessonLiveClass from "views/admin/cms/LessonLiveClass";
import LessonLiveClassForm from "views/admin/cms/LessonLiveClassForm";
import LessonLiveEdition from "views/admin/cms/LessonLiveEdition";
import LessonLiveEditionForm from "views/admin/cms/LessonLiveEditionForm";
import LessonLiveCalendarWebEx from "views/admin/cms/LessonLiveCalendarWebEx";
import { SurveyMain } from "views/survey";
import SurveyCmsList from "views/admin/cms/SurveyCmsList";
import { CorsiSingoliCSURCSU } from "views/public/pages/CorsiSingoliCSURCSU";
import CourseLessonLiveScheduleOnline from "views/course/live/CourseLessonLiveScheduleOnline";
import CourseLessonLiveScheduleOffline from "views/course/live/CourseLessonLiveScheduleOffline";
import RC30ConfirmExamSite from "components/plugin/rc30/RC30ConfirmExamSite";
import { BrowseSingleCourse } from "views/public/corsi-singoli";
import CourseUserSelfDeclaration from "views/course/CourseUserSelfDeclaration";
import RC30UDAExamWritten from "components/plugin/rc30/RC30UDAExamWritten";
import Review30Cfu from "views/admin/quizreview/Review30Cfu";
import ReviewPending from "views/admin/quizreview/ReviewPending";
import ViewerUDA from "views/admin/quizreview/ViewerUDA";
import ReviewPendingAdmin from "views/admin/quizreview/ReviewPendingAdmin";
import RC60CFUALL1UDAExamWritten from "components/plugin/rc60All1/RC60CFUALL1UDAExamWritten";
import RC30CFUALL2UDAExamWritten from "components/plugin/rc30All2/RC30CFUALL2UDAExamWritten";
import MUUM30TitleDownload from "components/plugin/um30/MUUM30TitleDownload";
import CFUALLExamReservation from "components/plugin/rc30All2/CFUALLExamReservation";

const Routes = ({ isLoggedIn }) => {

    return (
        <Switch>

            {/* PUBLIC */}

            <Route
                path={ROUTES.COURSE_SSD_BROWSE_OLD} exact
                component={() =>
                    <CourseBrowserSSD />
                }
            />

            <Route
                path={`${ROUTES.COURSE_SSD_BROWSE_OLD}/:classCode`} exact
                component={() =>
                    <CourseBrowserSSD />
                }
            />

            <Route
                path={ROUTES.COURSE_SSD_BROWSE} exact
                component={() =>
                    <CourseBrowserSSD />
                }
            />

            <Route
                path={`${ROUTES.COURSE_SSD_BROWSE}/:classCode`} exact
                component={() =>
                    <CourseBrowserSSD />
                }
            />

            <Route
                path={ROUTES.COURSE_MUPA_BROWSE} exact
                component={() =>
                    <CourseBrowserMUPA />
                }
            />

            <Route
                path={ROUTES.COURSE_BROWSE} exact
                component={() =>
                    <CourseBrowser isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={`${ROUTES.ENROLL_TFA_IX_CICLO}/:courseCode`} exact
                component={() => <CourseLandingPageFullLayout isLoggedIn={isLoggedIn} />}
            />

            {/*OLD ROUTES*/}
            <Route
                path='/24CFU' exact
                component={() =>
                    <Redirect to={ROUTES.ROOT} />
                }
            />

            <Route
                path='/24-cfu' exact
                component={() =>
                    <Redirect to={ROUTES.ROOT} />
                }
            />

            <Route
                path={ROUTES.ENROLL_30CFU} exact
                component={() =>
                    <Redirect to="/corso-online-mnemosine/RC30CFUART13" />
                }
            />

            <Route
                path='/corsi-mnemosine/24CFU' exact
                component={() =>
                    <Redirect to={ROUTES.ROOT} />
                }
            />

            <Route
                path='/corsi-mnemosine/U24CFU' exact
                component={() =>
                    <Redirect to={ROUTES.ROOT} />
                }
            />

            <Route
                path='/metodologia-clil' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCLIL' />
                }
            />

            <Route
                path='/altri-corsi-universitari' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/P-LVR' />
                }
            />

            <Route
                path='/corsi-singoli-strutturati-in-master-dettaglio' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/master-universitari' />
                }
            />

            <Route
                path='/corsi-singoli-strutturati-master' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/master-universitari' />
                }
            />


            <Route
                path='/diplomi-di-specializzazione-biennale-universitari-post-laurea' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/BU-I' />
                }
            />

            <Route
                path='/corsi-mnemosine/corsi-singoli' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCSU' />
                }
            />

            <Route
                path='/corsi-mnemosine/corsi-singoli-universitari' exact
                component={() =>
                    <BrowseSingleCourse isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path='/diplomi-perfezionamento-post-diploma-1500-ore-60-cfu' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/PDU' />
                }
            />

            <Route
                path='/diplomi-di-perfezionamento-universitari-annuali-post-laurea-specifici' exact
                component={() =>
                    <CourseBrowserPLUA />
                }
            />

            <Route
                path='/corsi-informatica' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/INFO' />
                }
            />
            <Route
                path='/clil' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCLIL' />
                }
            />

            <Route
                path='/clil-nota-1' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCLIL' />
                }
            />

            <Route
                path='/clil-nota' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCLIL' />
                }
            />

            <Route
                path='/corsi-mnemosine/CLIL' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/UCLIL' />
                }
            />

            <Route
                path='/certificazioni-esb' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/ENG' />
                }
            />

            <Route
                path='/aba-dettagliocorso' exact
                component={() =>
                    <Redirect to='/corso-online/ABA' />
                }
            />

            <Route
                path='/con-asuniver-e-mnemosine-dottorato-di-ricerca-in-spagna-unopportunita-di-crescita-professionale-per-accedere-alla-carriera-universitaria-con-tre-anni-di-congedo-retribuito' exact
                component={() =>
                    <Redirect to='/page/con-asuniver-e-mnemosine-dottorato-di-ricerca-in-spagna-unopportunita-di-crescita-professionale-per-accedere-alla-carriera-universitaria-con-tre-anni-di-congedo-retribuito' />
                }
            />

            <Route
                path='/InformativaTrattamentoDatiPersonali' exact
                component={() =>
                    <Redirect to='/page/informativa-dati-personali' />
                }
            />

            <Route
                path='/corso-online/ESBB2' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/ENG' />
                }
            />

            <Route
                path='/corso-online/ESBC1' exact
                component={() =>
                    <Redirect to='/corsi-mnemosine/ENG' />
                }
            />

            {/*END ROUTES*/}

            <Route
                path={`${ROUTES.COURSE_BROWSE}/:courseType`} exact
                component={() =>
                    <CourseBrowser isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={ROUTES.COURSE_BROWSE_OLD} exact
                component={() =>
                    <CourseBrowser isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_BROWSE_OLD}/:courseType`} exact
                component={() =>
                    <CourseBrowser isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_LANDINGPAGE}/:courseCode/`} exact
                component={() =>
                    <CourseLandingPage isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path='/corsi-mnemosine/RCPFU' exact
                component={() =>
                    <Redirect to='/corso-online-mnemosine/RCPFU5CFU' />
                }
            />

            <Route
                path='/page/corsi-abilitanti-60-e-30-cfu'
                component={() =>
                    <Redirect to={`${ROUTES.COURSE_LANDINGPAGE_FULL}/RCCFUALL`} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_LANDINGPAGE_FULL}/:courseCode/`} exact
                component={() =>
                    <CourseLandingPageFullLayout isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_LANDINGPAGE_HS}/:courseCode/`} exact
                component={() =>
                    <CourseLandingPage isLoggedIn={isLoggedIn} hiddenScoring={true} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_FULLLAYOUTPAGE}/:courseCode`} exact
                component={() =>
                    <CourseFullLayoutPage isLoggedIn={isLoggedIn} />
                }
            />

            <Route
                path={`${ROUTES.COURSE_LANDINGPAGE_IFRAME}/:courseCode`} exact
                component={() =>
                    <CourseLandingPageIFrame isLoggedIn={isLoggedIn} />
                }
            />

            <Route exact
                path={ROUTES.ROOT}
                component={() =>
                    <Landing />
                }
            />

            <Route
                path={ROUTES.PAGE_CONTACT_REQUEST} exact
                component={() =>
                    <PageContactRequest />
                }
            />

            <Route
                path={ROUTES.PAGE_CONTACT} exact
                component={() =>
                    <PageContact />
                }
            />
            <Route
                path={ROUTES.PAGE_WHOARE_OLD} exact
                component={() =>
                    <PageWhoAre />
                }
            />
            <Route
                path={ROUTES.PAGE_WHOARE_OLD2} exact
                component={() =>
                    <PageWhoAre />
                }
            />
            <Route
                path={ROUTES.PAGE_WHOARE} exact
                component={() =>
                    <PageWhoAre />
                }
            />

            <Route
                path={ROUTES.PAGE_SUPPORT} exact
                component={() =>
                    <PageSupportoTecnico />
                }
            />

            <Route
                path={`${ROUTES.PAGE_CONTACT_INFO}/:city`} exact
                component={() =>
                    <PageContactInfo />
                }
            />


            <Route
                path={ROUTES.QUALIFICATION_EVALUATION} exact
                component={() =>
                    <QualificationEvaluation />
                }
            />

            <Route
                path={ROUTES.TITLE_DELIVERY} exact
                component={() =>
                    <TitleDelivery />
                }
            />

            <Route
                path={ROUTES.TITLE_DELIVERY_ESB} exact
                component={() =>
                    <TitleDeliveryESB />
                }
            />

            <Route
                path={ROUTES.QUALIFICATION_MAP} exact
                component={() =>
                    <QualificationMap />
                }
            />

            <Route
                path={ROUTES.LOGIN}
                component={props =>
                    isLoggedIn ? <Redirect to={ROUTES.DASHBOARD} /> : <Login {...props} />
                }
            />
            <Route
                path={ROUTES.LOGIN_OLD}
                component={props =>
                    isLoggedIn ? <Redirect to={ROUTES.DASHBOARD} /> : <Login {...props} />
                }
            />

            <Route
                path={ROUTES.REGISTER}
                component={() =>
                    isLoggedIn ? <Redirect to={ROUTES.DASHBOARD} /> : <Registration />
                }
            />
            <Route
                path={`${ROUTES.PASSWORD_RESET}`}
                component={() =>
                    isLoggedIn ? <Redirect to={ROUTES.DASHBOARD} /> : <PasswordReset />
                }
            />
            <Route
                path={`${ROUTES.PASSWORD_RESET_TOKEN}/:token?`}
                component={() =>
                    isLoggedIn ? <Redirect to={ROUTES.DASHBOARD} /> : <PasswordResetToken />
                }
            />

            <Route
                path={`${ROUTES.PASSWORD_RESET_UNTOKEN}`}
                component={() =>
                    isLoggedIn ? <PasswordResetToken /> : <Redirect to={ROUTES.DASHBOARD} />
                }
            />

            {/* PUBLIC PROMO  */}
            <Route
                path={ROUTES.PROMO_AMICI} exact
                component={() =>
                    <PromoFriend />
                }
            />

            <Route
                path={ROUTES.PROMO_FAMIGLIA} exact
                component={() =>
                    <PromoFamily />
                }
            />

            <Route
                path={ROUTES.PROMO_ISTITUTO} exact
                component={() =>
                    <PromoIstitute />
                }
            />

            <Route
                path={ROUTES.PROMO_CORSO_GRATUITO} exact
                component={() =>
                    <PromoFreeCourse />
                }
            />

            <Route
                path={ROUTES.PROMO_50} exact
                component={() =>
                    <Promo50 />
                }
            />

            <Route
                path={ROUTES.PROMO_TABLET} exact
                component={() =>
                    <PromoTablet />
                }
            />

            {/*Pages varie*/}
            <Route
                path={ROUTES.PAGE_GSUITEDETAILS} exact
                component={() =>
                    <PageGsuiteDetails />
                }
            />

            <Route
                path={ROUTES.PAGE_24CFUDETAILS} exact
                component={() =>
                    <Redirect to={ROUTES.ROOT} />
                }
            />

            <Route
                path={ROUTES.PAGE_NEWSCANCEL} exact
                component={() =>
                    <NewsCancel />
                }
            />

            <Route
                path={`${ROUTES.BLOG_HOME}/:slug?`} exact
                component={() =>
                    <BlogHome />
                }
            />

            <Route
                path={`${ROUTES.BLOG_HOME}-:page?/:slug?`} exact
                component={() =>
                    <BlogHome />
                }
            />

            <Route
                path={`${ROUTES.BLOG_ARTICLE}/:slug?`} exact
                component={() =>
                    <BlogArticle />
                }
            />

            <Route
                path={ROUTES.PAGE_RULE} exact
                component={() =>
                    <PageRule />
                }
            />

            <Route
                path={ROUTES.PAGE_SOLIDARITY} exact
                component={() =>
                    <PageSolidarity />
                }
            />

            <Route
                path={ROUTES.PAGE_SOLIDARITY_2} exact
                component={() =>
                    <PageSolidarity2 />
                }
            />

            <Route
                path={ROUTES.PAGE_LEONI_SICANI} exact
                component={() =>
                    <PageLeoniSicani />
                }
            />

            <Route
                path={ROUTES.PAGE_UNICEF} exact
                component={() =>
                    <PageUnicef />
                }
            />

            <Route
                path={ROUTES.PAGE_CONCESSIONS} exact
                component={() =>
                    <Concessions />
                }
            />

            <Route
                path={ROUTES.PAGE_BENEFITSC24} exact
                component={() =>
                    <BenefitsC24 />
                }
            />

            <Route
                path={ROUTES.PAGE_BENEFITSM24} exact
                component={() =>
                    <BenefitsM24 />
                }
            />

            <Route
                path={ROUTES.PAGE_REFERENCELEGISLATION} exact
                component={() =>
                    <ReferenceLegislation />
                }
            />

            <Route
                path={ROUTES.PAGE_ENGLISHCERTIFICATION} exact
                component={() =>
                    <EnglishCertification />
                }
            />

            <Route
                path={ROUTES.PAGE_PERFEZIONAMENTOPDUPLU} exact
                component={() =>
                    <PerfezionamentoPduPlu />
                }
            />

            <Route
                path={ROUTES.PAGE_MASTERMUPAMSLU} exact
                component={() =>
                    <MasterMUPAMSLU />
                }
            />

            <Route
                path={ROUTES.PAGE_CORSISINGOLICSURCSU} exact
                component={() =>
                    <CorsiSingoliCSURCSU />
                }
            />

            {/* PUBLIC clil */}
            <Route
                path={ROUTES.PAGE_BENEFITSCLIL} exact
                component={() =>
                    <BenefitsClil />
                }
            />


            {/* PUBLIC ATA */}
            <Route
                path={ROUTES.PAGE_ACCESSTITLEATA} exact
                component={() =>
                    <AccessTitleATA />
                }
            />

            <Route
                path={ROUTES.PAGE_TEACHERCARD} exact
                component={() =>
                    <TeacherCard />
                }
            />

            <Route
                path={ROUTES.PAGE_DOCTORATE} exact
                component={() =>
                    <Doctorate />
                }
            />

            {/* START ADMIN  */}

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.EXAMPLES} exact
                component={<Examples />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.EXAMPLES}/:name`} exact
                component={<Examples />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.COURSE_MANAGER}
                component={<CourseManager />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_FORM}/:idcourse?/:idsection?`}
                component={<CourseForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.COURSE_TYPE_MANAGER}
                component={<CourseTypeManager />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TYPE_FORM}/:idcoursetype?`}
                component={<CourseTypeForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_FORM}/:idcourse/:idsection/:idlesson?/:action?/:currentlevel?`}
                component={<LessonForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.QUIZ_FORM}/:idcourse/:idsection/:idlesson/:idresource/:idquiz?`}
                component={<QuizForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_LIST}/student/enrollment/:idEnrollment`}
                component={<EnrollmentDetail />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_LIST}/student/course/:userId/:idCourse`}
                component={<CourseDetail />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_LIST}/:role/:id?`}
                component={<AdminUser />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_REPOQUIZ_MANAGER}`}
                component={<RepoQuiz />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.REPOQUIZ_FORM}/:idRepoQuiz?`}
                component={<RepoQuizForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PROMOTIONAL_MANAGER}/`}
                component={<Promotional />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PROMOTIONAL_FORM}/:idPromotional?`}
                component={<PromotionalDetail />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_SYNC_IN}`}
                component={<SyncIn />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.SITECONFIG_MANAGER}/`}
                component={<Siteconfig />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.SITECONFIG_FORM}/:idSiteConfig`}
                component={<SiteconfigDetail />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PAGE_MANAGER}`}
                component={<PageCmsList />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PAGE_FORM}/:idPage?`}
                component={<PageCmsForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.SURVEY_MANAGER}`}
                component={<SurveyCmsList />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.SURVEY_FORM}/:idSurvey?`}
                component={<SurveyCmsForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.BLOG_CATEGORY_MANAGER}`}
                component={<BlogCmsCategory />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.BLOG_CATEGORY_FORM}/:idBlogCategory?`}
                component={<BlogCmsCategoryForm />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.BLOG_ARTICLE_MANAGER}/:idCategory`}
                component={<BlogCmsArticle />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.BLOG_ARTICLE_FORM}/:idBlogArticle?`}
                component={<BlogCmsArticleForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_CATEGORY_MANAGER}`}
                component={<LibraryCmsCategory />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_CATEGORY_FORM}/:idLibraryCategory?`}
                component={<LibraryCmsCategoryForm />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_AUTHOR_MANAGER}`}
                component={<LibraryCmsAuthor />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_AUTHOR_FORM}/:idLibraryAuthor?`}
                component={<LibraryCmsAuthorForm />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_BOOK_MANAGER}/:mainFilter/:idLibraryBook?`}
                component={<LibraryCmsBook />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LIBRARY_BOOK_FORM}/:idLibraryBook?`}
                component={<LibraryCmsBookForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.QUALIFICATION_DATA_ANS_MANAGER}`}
                component={<ANSCmsMain />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSETS_AUDIO_CATEGORY_MANAGER}`}
                component={<AssetsAudioMain />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSETS_AUDIO_CATEGORY_FORM}/:idAudioCategory?`}
                component={<AssetsAudioMainForm />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSETS_AUDIO_FILE_MANAGER}/:idAudioCategory`}
                component={<AssetsAudioFileMain />}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSETS_AUDIO_FILE_FORM}/:idAudioCategory/:idAudio?`}
                component={<AssetsAudioFileMainForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.CURRENT_REVISION}/`}
                component={<ReviewCurrent />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.HISTORY_REVISION}/:idUser?`}
                component={<ReviewHistory />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PENDING_REVISION_ADMIN_30CFU}`}
                component={<ReviewPendingAdmin />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PENDING_REVISION}/:idUser?`}
                component={<ReviewPending />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.VIEWER_UDA}`}
                component={<ViewerUDA />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.QUIZREVIEW_FORM}/:idQuizReview`}
                component={<Review />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.EXAMREVIEW_FORM}/:idExamReview`}
                component={<Review30Cfu />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.CONTACT_REQUEST}
                component={<ContactRequest />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.LESSON_LIVE_SCHEDULE}
                component={<LessonLiveSchedule />}
            />


            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.LESSON_LIVE_PROCESS_ANALYTICS}
                component={<LessonLiveScheduleProcess />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_SCHEDULE_FORM}/:idLessonLiveSchedule?`}
                component={<LessonLiveScheduleForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_SCHEDULE_ANALYTICS}/:idLessonLiveSchedule?`}
                component={<LessonLiveScheduleAnalytics />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_CLASS}`}
                component={<LessonLiveClass />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_CLASS_FORM}/:idLessonLiveClass?`}
                component={<LessonLiveClassForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_EDITION}`}
                component={<LessonLiveEdition />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_EDITION_FORM}/:idLessonLiveEdition?`}
                component={<LessonLiveEditionForm />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LESSON_LIVE_CALENDAR_WEBEX}`}
                component={<LessonLiveCalendarWebEx />}
            />

            {/* END ADMIN */}


            {/* START SEGRETERIA  */}

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_LIST}/:role/:id?`}
                component={<AdminUser />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PROMOTIONAL_MANAGER}/`}
                component={<Promotional />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PROMOTIONAL_FORM}/:idPromotional?`}
                component={<PromotionalDetail />}
            />
            {/* END SEGRETERIA */}

            <Route exact
                path={`${ROUTES.PAYMENT_RESULT_OK}/:orderNumber/:tableName`}
                component={() => <CardPaymentOK />
                }
            />

            <Route exact
                path={`${ROUTES.PAYMENT_RESULT_KO}/:orderNumber/:tableName`}
                component={() => <CardPaymentKO />
                }
            />

            <Route exact
                path={`${ROUTES.PAYMENT_RESULT_NOTIFY}`}
                component={() => <CardPaymentNotify />
                }
            />

            <Route exact
                path={`${ROUTES.PAYMENT_GOMP_RESULT_NOTIFY}/:orderNumber?`}
                component={() => <GOMPPaymentNotify />
                }
            />

            <Route path={ROUTES.CONFIRM} component={Confirm} />

            {/* STUDENT  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MY_ENROLLMENT}/:orderNumber`}
                component={<SP><Enrollment /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MY_ENROLLMENT_PENDING}
                component={<SP><EnrollmentPending /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.DASHBOARD + '/:context?'}
                component={<SP><Dashboard /></SP>}
            />

            {/* MY  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MY_PROFILE}
                component={<SP><MyProfile /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MY_QUALIFICATION}
                component={<SP><MyQualifications /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MY_DOCUMENT}
                component={<SP><MyDocument /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MY_INVOICEDATA}
                component={<SP><MyInvoiceData /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MY_QUIZREVIEW}/:idQuizReview`}
                component={<SP><MyQuizReview /></SP>}
            />
            {/* COURSE  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.COURSE_LIST}
                component={<SP><CourseList /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_PAYMENTS}/:rCode?`}
                component={<SP><CoursePayments /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_PAYMENT_CD}/:rCode/:numRate`}
                component={<SP><CoursePaymentCD /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_SURVEY_MAIN}/:scode/:rCode`}
                component={<SP><SurveyMain /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_BOOKING_CLASS}/:rCode`}
                component={<SP><CourseBookingClass /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30_EXAM_RESERVATION}/:rCode`}
                component={<SP><RC30ExamReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30_EXAM_SUBJECT}/:rCode`}
                component={<SP><RC30ExamSubject /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30_CERT_REQUEST}/:rCode?`}
                component={<SP><RC30CertificationRequest /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30_CONFIRM_EXAMSITE}/:rCode?`}
                component={<SP><RC30ConfirmExamSite /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30_UDA_EXAMWRITTEN}/:rCode?`}
                component={<SP><RC30UDAExamWritten /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC60CFUALL1_UDA_EXAMWRITTEN}/:rCode?`}
                component={<SP><RC60CFUALL1UDAExamWritten /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_RC30CFUALL2_UDA_EXAMWRITTEN}/:rCode?`}
                component={<SP><RC30CFUALL2UDAExamWritten /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE}/:rCode?`}
                component={<SP><CourseLessonLiveScheduleOnline /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_LESSONLIVE_SCHEDULE_OFFLINE}/:rCode?`}
                component={<SP><CourseLessonLiveScheduleOffline /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_LESSONLIVE_DETAIL}/:rCode/:idLessonLive`}
                component={<SP><LessonLiveDetail /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_SHOW_FILE}/:rCode/:fileId`}
                component={<SP><ShowFile /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TICKETS}/:rCode?`}
                component={<SP><CourseTickets /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_MANIFESTO}/:rCode?`}
                component={<SP><CourseManifesto /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TICKET_DETAIL}/:id`}
                component={<SP><CourseTicketDetail /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_INVOICES}/:rCode?`}
                component={<SP><CourseInvoices /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_EXAM_WRITTEN_RESERVATION}/:rCode?`}
                component={<SP><ExamWrittenReservation /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION}/:rCode?`}
                component={<SP><PerfezionamentoExamFinalOnsiteReservation /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PERFEZIONAMENTO_EXAM_WRITTEN}/:rCode?`}
                component={<SP><PerfezionamentoExamWritten /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_EXAM_LIVE_RESERVATION}/:rCode?`}
                component={<SP><PerfezionamentoExamLiveReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PERFEZIONAMENTO_EXAM_LIVE}/:rCode?`}
                component={<SP><PerfezionamentoExamLive /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_EXAM_DONE}/:rCode?`}
                component={<SP><ExamDone /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_DOCS}/:rCode?`}
                component={<SP><CourseDocs /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_ISTRUCTION}/:rCode?`}
                component={<SP><CourseIstruction /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TUTOR}/:rCode?`}
                component={<SP><CourseTutor /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_PROVIDER}/:rCode?`}
                component={<SP><CourseProvider /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TITLE_REQUEST}/:rCode?`}
                component={<SP><CourseTitleRequest /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_DASHBOARD}/:rCode`}
                component={<SP><CourseDashboard /></SP>}
            />
            {/* LEARNING  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LEARNING_MAIN}/:rCode`}
                component={<SP><CourseMain /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LEARNING_LESSON}/:id/:rCode?/:idModule?/:idResource?`}
                component={<SP><Lesson /></SP>}
            />

            {/* MU  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_COURSE_PAYMENTS}/:rCode?`}
                component={<SP><MUCoursePayments /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_COURSE_TITLE_REQUEST}/:rCode?`}
                component={<SP><MUCourseTitleRequest /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_UM_TITLE_DONWLOAD}/:rCode?`}
                component={<SP><MUUM30TitleDownload /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_C24_CERT_REQUEST}/:rCode?`}
                component={<SP><MUC24CertificationRequest /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_COURSE_DOCS}/:rCode?`}
                component={<SP><MUCourseDocs /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_PAYMENT_REQUEST}/:rType/:rCode`}
                component={<SP><MUCoursePaymentRequest /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MU_SELF_DECLARATION}/:rCode?`}
                component={<SP><CourseSelfDeclaration /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USER_SELF_DECLARATION}/:rCode?`}
                component={<SP><CourseUserSelfDeclaration /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TITLE_SELF_DECLARATION}/:rCode?`}
                component={<SP><CourseTitleSelfDeclaration /></SP>}
            />

            {/* ----------------- START 24CFU ----------------- */}

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_EXAMWRITTEN_RESERVATION}/:rCode?`}
                component={<SP><C24ExamWrittenReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_EXAMWRITTEN_ONSITE_RESERVATION}/:rCode?`}
                component={<SP><C24ExamWrittenOnsiteReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_EXAMWRITTEN}/:rCode?`}
                component={<SP><C24ExamWritten /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_EXAMLIVE_RESERVATION}/:rCode?`}
                component={<SP><C24ExamLiveReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_EXAMLIVE}/:rCode?`}
                component={<SP><C24ExamLive /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.C24_CERT_REQUEST}/:rCode?`}
                component={<SP><C24CertificationRequest /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_CFU_SELF_DECLARATION}/:rCode?`}
                component={<SP><CourseCFUSelfDeclaration /></SP>}
            />

            {/* ----------------- END 24CFU ------------------- */}

            {/* ICDL  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ICDL_CERTIFICATION}/:rCode`}
                component={<SP><ICDLCertification /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ICDL_LESSON}/:rCode`}
                component={<SP><ICDLLesson /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ICDL_EXAM_RESERVATION}/:rCode`}
                component={<SP><ICDLExamReservation /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.CFUALL_EXAM_RESERVATION}/:rCode`}
                component={<SP><CFUALLExamReservation /></SP>}
            />

            {/* M24  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.M24_LESSON}/:rCode`}
                component={<SP><M24Lesson /></SP>}
            />

            {/* ucsu  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.UCSU_LESSON}/:rCode`}
                component={<SP><UCSULesson /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.USCU_CERT_REQUEST}/:rCode?`}
                component={<SP><UCSUCertificationRequest /></SP>}
            />

            {/* rcsu  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.RCSU_LESSON}/:rCode`}
                component={<SP><RCSULesson /></SP>}
            />

            {/* ESB  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ESB_LESSON}/:rCode`}
                component={<SP><ESBLesson /></SP>}
            />
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ESB_EXAM_RESERVATION}/:rCode`}
                component={<SP><ESBExamReservation /></SP>}
            />

            {/* MNE */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSISTANCE_TELEPHONE_MNE}/:rCode?`}
                component={<SP><AssistanceTelephoneMNE /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.ASSISTANCE_ONLINE_MNE}/:rCode?`}
                component={<SP><AssistanceOnlineMNE /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.COURSE_TUTOR_MNE}/:rCode?`}
                component={<SP><CourseTutorMNE /></SP>}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.MNE_SIMULATION_EXAM}/:rCode`}
                component={<SP><MNESimulationExam /></SP>}
            />

            {/* TOOLS  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.MOBILE_CONFIRM}
                component={<SP><MobileConfirm /></SP>}
            />

            {/* AGENDA  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.AGENDA}
                component={<SP><Agenda /></SP>}
            />

            {/* CAREER  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={ROUTES.CAREER}
                component={<SP><Career /></SP>}
            />

            {/* FINAL PAPER  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LEARNING_FINAL_PAPER}/:rCode/:moduleProgress?`}
                component={<SP><FinalPaper /></SP>}
            />


            {/* QUIZ  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LEARNING_QUIZ_FEEDBACK}/:idQuizAttempt/:rCode?`}
                component={<SP><QuizFeedbackFinal /></SP>}
            />

            {/* QUIZ / QUIZ RESUME ATTEMPT  */}
            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.LEARNING_QUIZ_MAIN}/:id/:idqa?/:rCode?/:action?`}
                component={<SP><QuizMain /></SP>}
            />

            <Route
                path={`${ROUTES.PAGE}/:slug`} exact
                component={() =>
                    <Page />
                }
            />

            {/* BIBLIOTECA */}
            <Route
                path={ROUTES.PAGE_BIBLIOTECA}
                exact
                component={() => <Biblioteca />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/libri/`}
                exact
                component={() => <ListBooks />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/categorie/:slug`}
                exact
                component={() => <CategoryBooks />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/libri/:slug`}
                exact
                component={() => <Book />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PAGE_BIBLIOTECA}/libri/libro/:slug`}
                exact
                component={<BookViewer />}
            />

            <ProtectedRoute
                isLoggedIn={isLoggedIn}
                path={`${ROUTES.PAGE_BIBLIOTECA}/wishlist`}
                exact
                component={<Wishlist />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/autori/`}
                exact
                component={() => <Authors />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/autori/:slug`}
                exact
                component={() => <Author />}
            />

            <Route
                path={`${ROUTES.PAGE_BIBLIOTECA}/categorie`}
                exact
                component={() => <BooksCategories />}
            />

            {/* END BIBLIOTECA */}

            {/* SEDI D'ESAME */}
            <Route
                path={ROUTES.PAGE_SEDI_ESAMI}
                exact
                component={() => <PageSediEsami />}
            />
            {/* END SEDI D'ESAME */}

            {/* FORM ADESIONE 5CFU */}
            <Route
                path={ROUTES.FORM_30CFU_UNICAMILLUS}
                exact
                component={() => <Form30CfuUnicamillusView />}
            />
            {/* END FORM ADESIONE 5CFU */}

            {/* FORM ADESIONE 30CFU unicamillus */}
            <Route
                path={ROUTES.FORM_5CFU}
                exact
                component={() => <Form5CfuView />}
            />
            {/* END FORM ADESIONE 5CFU */}


            {/* FORM ADESIONE 5CFU */}
            <Route
                path={ROUTES.FORM_60CFU}
                exact
                component={() => <Form60CfuView />}
            />

            <Route
                path={ROUTES.FORM_60CFU_EMBEDDED}
                exact
                component={() => <Form60cfuEmbedded />}
            />
            {/* END FORM ADESIONE 5CFU */}

            <Route
                path={`${ROUTES.PAGE_CORSIDILAUREA}`}
                exact
                component={() => <PageCorsiDiLaurea />}
            />

            {/* Corsi di laurea */}

            {/* end corsi di laurea */}
            {/* 404 not found  */}
            <Route path="/404" component={NotFoundPage} />

            {/* 401 unauthorized  */}
            <Route path="/401" component={Unauthorized} />

            <Redirect to="/404" />
        </Switch>
    );
};

export default Routes;
