import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import {
  Header,
  HeaderContent,
  HeaderToggler,
  Collapse,
  Nav,
  NavItem,
  Icon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  LinkList,
} from "design-react-kit";

import { ROUTES } from "../../const";

export class NHSegreteria extends Component {
  state = {
    collapsed: true,
  };

  manageEvtByLink = (link) => {
    this.setState({ collapsed: true });
  };

  //--- ---

  render() {
    const isOpen = !this.state.collapsed;

    return (
      <>
        <Header small theme="" type="navbar">
          <HeaderContent expand="lg" megamenu>
            <HeaderToggler
              aria-controls="nav1"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ collapsed: isOpen })}>
              <Icon icon="it-burger" />
            </HeaderToggler>
            <Collapse
              isOpen={isOpen}
              navbar
              header
              onOverlayClick={() => this.setState({ collapsed: true })}>
              <div className="menu-wrapper mnemo-dark">
                <Nav navbar tag="ul" vertical={false}>
                  <NavItem
                    key={`menu-item-close`}
                    tag="li"
                    onClick={() => this.setState({ collapsed: isOpen })}>
                    <div className="mnemo-close-li">
                      CHIUDI <Icon icon="it-close-circle" padding={false} />
                    </div>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Area Revisore
                        </DropdownToggle>

                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">

                          <LinkList tag="div">
                            <NavItem tag="li">
                              <b className="px-4 text-white">Revisioni 30Cfu</b>
                            </NavItem>
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.DASHBOARD + "/30cfu"}
                                ag="a">
                                Revisioni Correnti
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.PENDING_REVISION_ADMIN_30CFU}
                                ag="a">
                                Revisioni non idonee
                              </NavLink>
                            </NavItem>

                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={ROUTES.VIEWER_UDA}
                                ag="a">
                                Consultazione UDA
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li">
                    <UncontrolledDropdown nav tag="div">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup
                          caret
                          color="secondary"
                          nav>
                          Gestione Utenti
                        </DropdownToggle>
                        <DropdownMenu
                          flip
                          ag="div"
                          className="dropdown-menu-show-fix mnemo-dark">
                          <LinkList tag="div">
                            <NavItem
                              tag="li"
                              onClick={() => this.manageEvtByLink()}>
                              <NavLink
                                className="list-item"
                                to={`${ROUTES.USER_LIST}/student`}
                                tag="a">
                                Studenti
                              </NavLink>
                            </NavItem>
                          </LinkList>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </UncontrolledDropdown>
                  </NavItem>

                  <NavItem tag="li" onClick={() => this.manageEvtByLink()}>
                    <NavLink
                      className="nav-link"
                      to={ROUTES.PROMOTIONAL_MANAGER}
                      ag="a">
                      Promozioni
                    </NavLink>
                  </NavItem>

                  <NavItem tag="li" onClick={this.props.logout}>
                    <NavLink className="nav-link" to="/" ag="a">
                      Esci
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Collapse>
          </HeaderContent>
        </Header>
      </>
    );
  }
}
