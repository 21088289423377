import React, { Component } from "react";
import { MSection, Title } from "components/misc";
import { Helmet } from "react-helmet";
import { Row, Col } from "design-react-kit";

export class Form60CfuView extends Component {

    render() {

        const title =
            "";

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <div className="text-center">
                                <Title>{title}</Title>
                            </div>
                            <div className="text-center">
                                <h2><span className="text-red">Corsi Abilitanti 30 CFU online attivi!</span></h2><p>(ex art.13 DPCM 4 agosto 2023)</p>
                                <h2><span className="text-red">SOLO per docenti già abilitati su materia o sul sostegno</span></h2>
                                <h3><span className="text-red">2024/2025</span></h3></div>
                            <div>
                                <br />
                                <p>
                                    Il Ministero ha autorizzato le Università all'attivazione dei <b>Corsi 30 CFU in modalità interamente online.</b>
                                </p>
                                <h5>Destinatari:</h5>
                                <ol type="a">
                                    <li>Docenti Specializzati sul sostegno;</li>
                                    <li>Docenti abilitati in una qualsiasi classe di concorso: dalla scuola dell'infanzia alla scuola secondaria.</li>
                                </ol>
                                <p>I suddetti docenti <b>potranno richiedere:</b></p>
                                <p>a) abilitazione in una qualsiasi classe di concorso (purché in possesso di titolo valido);</p>
                                <p><b>ciò al fine di:</b></p>
                                <p>a) inserirsi nelle graduatorie come docenti abilitati;<br />
                                    b) richiedere la mobilità o l'assegnazione provvisoria anche in altro grado di istruzione;</p>
                                <hr />
                                <h5>Corsi attualmente attivi:</h5>
                                <ul>
                                    <li>A011 - Discipline Letterarie e Latino - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A012 - Discipline Letterarie negli Istituti di Istruzione Secondaria di II Grado - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A019 - Filosofia e Storia - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A022 - Italiano, Storia, Geografia nella Scuola Secondaria di I Grado - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A028 - Matematica e Scienze - € 1.500 <a href="../corso-online-mnemosine/UM30CFU">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A030 - Musica nella scuola secondaria di I grado - € 1.780 <a href="../corso-online-mnemosine/CM30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A031 - Scienze degli alimenti - € 1.500 <a href="../corso-online-mnemosine/UM30CFU">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A045 - Scienze Economico-Aziendali - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A046 - Scienze Giuridico-Economiche - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A048 - Scienze Motorie e Sportive negli Istituti di Istruzione Secondaria di II Grado - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A049 - Scienze Motorie e Sportive nella Scuola Secondaria di I Grado - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A050 - Scienze naturali, chimiche e biologiche - € 1.500 <a href="../corso-online-mnemosine/UM30CFU">(informazioni e modalità di iscrizione);</a></li>
                                    <li>A054 - Storia dell'Arte - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>AB24 - Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Inglese) - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>AB25 - Lingua Inglese e Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Inglese) - € 1.780 <a href="../corso-online-mnemosine/RC30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>AB56 - Strumento musicale nella scuola secondaria di I grado (Chitarra) - € 1.780 <a href="../corso-online-mnemosine/CM30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>AN55 - Violoncello - € 1.780 <a href="../corso-online-mnemosine/CM30CFUART13">(informazioni e modalità di iscrizione);</a></li>
                                    <li>B012 - Laboratori di scienze e tecnologie chimiche e microbiologiche - € 1.500 <a href="../corso-online-mnemosine/UM30CFU">(informazioni e modalità di iscrizione);</a></li>
                                </ul>
                                <hr />
                                <div className="text-center"><h3>Edizione Attiva 2024/2025</h3></div>
                                <p><b>Lezioni:</b> on-line in modalità sincrona<br /><br />
                                    <b>Calendario Corsi</b> Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023): le lezioni online si svolgeranno in Inverno. Sarà possibile scegliere di frequentare le lezioni o nei giorni infrasettimanali (orari pomeridiani) o nei weekend.<br /><br />
                                    <b>Termine Lezioni</b> ed <b>esami finali</b>:<br />
                                    <p>Entro l’aggiornamento delle domande di Mobilità/Trasferimento 2025 personale docente a tempo indeterminato (già di Ruolo).</p>
                                    <b>Sedi Esami finali:</b> <br />
                                    Agrigento, Ancona, Bari, Bologna, Cagliari, Caserta/Cassino, Catania, Catanzaro, Como, Cosenza, Firenze, Foggia, Genova, Grosseto, Lecce, Matera, Mazara del Vallo (TP), Messina, Milano, Napoli, Novara/Alessandria, Nuoro/Sassari, Padova/Venezia, Palermo, Perugia/Terni, Pescara, Pisa, Reggio di Calabria, Roma, Sciacca, Torino, Udine, Verona, Vibo Valentia e Vittoria (RG).
                                    <br /><br />
                                    <b>Struttura Corso</b>: 180 ore di lezioni suddiviso in due Attività Formative di 90 ore cadauno Per ciascuna attività formativa sarà consentita l'assenza ad un numero massimo di 4 lezioni o 27 ore complessive.<br />
                                    1 CFU corrisponde a n. 6 ore di attività formativa di cui è responsabile il Docente.<br />
                                    Ammissione alla prova finale: si è ammessi se non si supera il limite massimo di assenze.<br />

                                </p><hr />
                                <h5>Prova finale</h5>
                                <p><b>Prova scritta</b>: consiste in un intervento di progettazione didattica innovativa, anche mediante tecnologie digitali multimediali, afferenti alla disciplina o alle discipline della classe di concorso per la quale è conseguita l'abilitazione</p>
                                <p><b>Lezione simulata</b> - durata MAX di 45 min.: consiste nella progettazione, anche mediante tecnologie digitali multimediali, di un'attività didattica innovativa, comprensiva dell'illustrazione delle scelte contenutistiche, didattiche e metodologiche compiute in riferimento al percorso di formazione iniziale relativo alla specifica classe di concorso.</p>
                                <hr />
                                <h3>Costi (*)</h3>
                                <p>I costi dei corsi variano da € 1.500 ad € 1.780 + 16 (marca da bollo) rateizzabili in n. 3 versamenti.</p>
                                <p>(*) come previsto dal DPCM 4 Agosto 2023 per accedere alla prova finale é previsto un versamento di € 150 (Centocinquanta/00).</p>
                                <hr />
                                <h3>Modalità di pagamento</h3>
                                <p>Bonifico Bancario: per i Percorsi erogati dall’Università “Dante Alighieri” di R.C.</p>
                                <p>PagoPA: per i Percorsi erogati dall’Università Unicamillus di Roma e dal Conservatorio Statale di Musica “P.I.Tchaikovksy”.</p>
                                <p>Carta docenti: per i Percorsi erogati dall’Università Unicamillus di Roma e dall’Università “Dante Alighieri” di R.C. (solo con l’Università “Dante Alighieri” di R.C.  la carta è utilizzabile solo per la seconda e/o terza rata).</p>
                                <hr />
                                <p><b>Scadenza iscrizioni: Giovedì 19 Dicembre 2024</b></p>
                                <hr />

                                {/*<h5><a href={ROUTES.ENROLL_30CFU}>Iscrizioni Aperte. Clicca qui</a></h5>*/}
                                <p> E-mail: <a href="mailto:abilitazione@formazionedocenti.it">abilitazione@formazionedocenti.it</a></p>
                                <br />
                                {/*<br />
                                <p>
                                    <b>Percorsi da 60 CFU</b> (All. 1 DPCM 4 agosto 2023)<br />
                                    Riservato a corsisti in possesso di Laurea e/o Diploma ITP e/o studenti regolarmente iscritti a corsi di studio per il conseguimento dei titoli idonei all'insegnamento purché abbiano già conseguito almeno 180 CFU.
                                    Ai sensi dell'art. 2-ter del D.Lgs. sopra citato, gli iscritti ai corsi di laurea magistrale possono accedere alla prova finale solo a seguito del conseguimento del titolo.

                                </p>
                                <p>
                                    <b>Percorsi da 30 CFU</b> (All. 2 DPCM 4 agosto 2023)<br />
                                    Riservato ai vincitori del concorso che non hanno l'abilitazione all'insegnamento e hanno partecipato alla procedura concorsuale ai sensi dell'art. 5, c. 4, del D.Lgs. del 13 aprile 2017 n. 59

                                </p>
                                <p><b>Percorsi da 30 CFU</b> – primo periodo (All. 3 DPCM 4 agosto 2023)<br />
                                    Riservato a coloro che sono in possesso dei titoli coerenti con la relativa classe di concorso e intendono partecipare ai concorsi straordinari previsti entro il 31 dicembre 2024
                                </p>
                                <p><b>Percorsi da 30 CFU</b> - secondo periodo (All. 4 DPCM 4 agosto 2023)<br />
                                    Riservato a coloro che hanno completato il Percorso formativo di cui sopra (All. 3) e siano risultati vincitori del concorso
                                </p>

                                <p><b>Percorsi da 30 CFU</b> - di cui all'articolo 2 ter, comma 4 bis, del decreto legislativo 13 aprile 2017, n. 59<br />
                                    Riservato a coloro che hanno svolto servizio presso le istituzioni scolastiche statali o presso le scuole paritarie
                                    per almeno tre anni, anche non continuativi, di cui almeno uno nella specifica classe di concorso per la quale scelgono di conseguire l'abilitazione, nei cinque anni precedenti, nonché coloro che hanno sostenuto la prova concorsuale relativa alla procedura straordinaria di cui all'articolo 59, comma 9-bis,
                                    del decreto-legge 25 maggio 2021, n. 73, convertito, con modificazioni, dalla legge 23 luglio 2021, n. 106.
                                </p>

                                <p><b>Percorsi da 36 CFU</b> (All. 5 DPCM 4 agosto 2023)<br />
                                    Riservato a coloro che siano risultati vincitori di concorso con possesso dei 24 CFU nelle discipline antropo-psico-pedagogiche conseguiti entro il 31/10/2022.

                            </p>*/}
                            </div>
                        </Col>
                    </Row>
                </MSection>
            </>
        );
    }
}
