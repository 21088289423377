import {proxyBE} from './common';

export const learningLiveService = { 
    
    MyScheduleStageOnline(courseId, stageNumber) {        

        let url = `/learning/live/my-schedule-stage-online/${courseId}/${stageNumber}`;
        return proxyBE.get(url);
    },

    MyScheduleStageOffline(courseId, stageNumber) {        
        return proxyBE.get(`/learning/live/my-schedule-stage-offline/${courseId}/${stageNumber}`);
    },

    myLesson(id, registrationCode) {        
        return proxyBE.get('/learning/live/my-lesson/'+registrationCode+'/'+id);
    },

    generateAttendance(payload) {        
        return proxyBE.post('/learning/live/create-attendance/', {payload});
    },

    myAnalyticResume(rCode) {        
        return proxyBE.post('/learning/live/my-analytics-resume/'+rCode);
    },

    donwloadAttendance(id) {        
        return proxyBE.get('/learning/live/download-attendance/'+id);
    },

    loadFile(fileId){
        return proxyBE.get('/lessonliveschedule-file-slide/get/'+fileId);
    },

    myOpenClassBooking(registrationCode) {
        return proxyBE.get('/learning/live/class-booking-my/'+registrationCode);
    },
   
    isOpenClassBooking(registrationCode) {
        return proxyBE.get('/learning/live/class-booking-isopen/'+registrationCode);
    },
}