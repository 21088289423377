import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCurrentUser } from "store/actions";
import ReviewPending30Cfu from "./ReviewPending30Cfu";

class ReviewPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUser: this.props.match.params.idUser || 0,
    };
  }
  

  //--- ---

  render() {
    const { idUser } = this.state;
    const user = getCurrentUser();

    return (
      <>
        {(user.revisor.context === '30cfu') && <ReviewPending30Cfu idUserRevisor={idUser} />}
      </>
    );
  }
}

export default withRouter(ReviewPending);
