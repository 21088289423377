import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger,  MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { learningService, networkErrorHelper } from "libs";
import { Icon } from "design-react-kit";
import { ROUTES } from "const";

class CFUALLExamReservation extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, loading: false, loadingError: false, rCode });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    renderIFrame() {

        const { course, rCode } = this.state;
        const aaURL = course.aa.toString().slice(-2);
        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL)
            + 'moodleExam/index/rCode/' + rCode;
        let height = '10500px';

        return (
            <>
                <div>
                    <embed src={src} width="99%" height={height} />
                </div>
            </>
        );
    }

    render() {

        const { loading, loadingError,rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const info = this.renderIFrame();

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Prenotazione esami</Title>
            {info}
        </>)
    }
}

export default withRouter(CFUALLExamReservation);