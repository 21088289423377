import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  BoxDanger,
  BoxSuccess,
  MnemoCallout,
  MnemoLoading,
  ModalHandler,
  SupportoTecnico,
  Title,
} from "components/misc";
import { dateHelper, examService, networkErrorHelper } from "libs";
import { Button, Icon, Row, Col } from "design-react-kit";
import { ROUTES } from "const";
import {
  FormEnabler,
  MLabel,
  MTextArea,
  ValidatedForm,
} from "components/forms";

import ReactHtmlParser from "react-html-parser";

class RC60CFUALL1UDAExamWritten extends ValidatedForm {
  
  DAYS_DEADLINE = 5;
  HOUR_DEADLINE = "20:00";

  emptyFields = {
    introduzione: "",
    episodi: "",
    consoderazioni: "",
  };

  state = {
    loading: true,
    loadingError: false,
    showSuccess: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    examQuestion: [],
    rCode: this.props.match.params.rCode,
    exam: {},
    examRevision: {},
  };

  FIELDS_GROUP = [
    [
      {
        label: "Introduzione",
        for: "introduzione",
        infoText:
          "In questa sezione il/la candidato/a dovrà fare una descrizione del contesto dell'istituzione scolastica presso cui si è svolto il tirocinio, il periodo, le classi e i docenti coinvolti, nonché considerazioni generali sull'esperienza svolta utili a contestualizzare gli episodi presentati nella sezione successiva. (massimo 3000 battute spazi inclusi).",
        component: MLabel,
        className: "col-md-12  pt-3",
      },
    ],
    [
      {
        field: "introduzione",
        question: "Introduzione",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 3000,
      },
    ],
    [
      {
        label: "Episodi significativi",
        for: "episodi",
        infoText: `Questa sezione dovrà contenere l'analisi critica di 2-4 episodi “problematici” e/o situazioni interessanti dal punto di vista della metodologia didattica e della gestione del gruppo-classe, osservati durante l'esperienza di tirocinio. Si consiglia di includere considerazioni relative alle seguenti domande-guida per il resoconto dell'episodio: 
          </br>- Cosa è accaduto?
          </br>- Quali erano gli attori coinvolti?
          </br>- Perché ritiene l'episodio interessante o innovativo?
          </br>- Come valuta gli esiti dell'episodio, es. il modo in cui il problema è stato risolto dal docente o gli effetti positivi della sua azione didattica? (massimo 6000 battute spazi inclusi). 
          </br></br> È una <b>presentazione "critica" delle attività svolte</b>, in relazione agli obiettivi formativi prefissati.`,
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "episodi",
        question: "Episodi significativi",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 6000,
      },
    ],
    [
      {
        label: "Considerazioni conclusive",
        for: "considerazioni",
        infoText:
          "In questa sezione il/la candidato/a dovrà trarre le conclusioni metodologico-didattiche generali circa gli episodi analizzati, facendo riferimento alla sua esperienza personale come insegnante e/o apprendente e ai contenuti acquisiti durante il PF60. Il/la candidato/a dovrà fare le valutazioni personali e le riflessioni sul tipo di esperienza vissuta durante il tirocinio. (max. 3.000 battute spazi inclusi).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "considerazioni",
        question: "Considerazioni conclusive",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 3000,
      },
    ],
  ];

  MAX_LEN = {
    introduzione: 3000,
    episodi: 6000,
    considerazioni: 3000,
  };

  ERROR_MESSAGES = {
    introduzione: `Specificare l'introduzione (max ${this.MAX_LEN["introduzione"]} caratteri)`,
    episodi: `Specificare gli episodi (max ${this.MAX_LEN["episodi"]} caratteri)`,
    considerazioni: `Specificare le considerazioni (max ${this.MAX_LEN["considerazioni"]} caratteri)`,
  };

  validation = {
    introduzione: (value) =>
      value !== "" && value.length < this.MAX_LEN["introduzione"],
    episodi: (value) => value !== "" && value.length < this.MAX_LEN["episodi"],
    considerazioni: (value) =>
      value !== "" && value.length < this.MAX_LEN["considerazioni"],
  };

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  goBack() {
    window.history.back();
  }

  onCancel = () => {
    this.setState({
      showExamSiteList: false,
      validationSummary: "",
    });

    this.resetForm();
  };

  componentDidMount() {
    this.loadRemoteExam(this.state.rCode);
  }

  loadRemoteExam(rCode) {
    examService
      .getExamByRCode(rCode)
      .then((data) => {
        const { exam, examQuestion, examRevision } = data.data.payload;

        let defaultValues = {};
        examQuestion.map((item) => {
          defaultValues[item.field] = item.answer;
          examQuestion[item.field] = { ...item, value: item.answer };
        });

        this.setState({
          defaultValues,
          examQuestion,
          examRevision,
          rCode,
          loading: false,
          loadingError: false,
          exam,
        });
      })
      .catch((errors) => {
        this.setState({ loading: false });
      });
  }

  /** */
  onSubmit = () => {
    this.setState({ loading: true, loadingError: false });

    /** save remote */
    this.setState({ registrationCode: this.state.rCode });
    let payload = {
      exam: {},
      examQuestion: [],
    };

    let progress = 1;

    //--- prepare examQuestion data ---
    this.FIELDS_GROUP.map((group) => {
      if (group[0].field) {
        let data = {
          field: group[0].field,
          question: group[0].question,
          answer: this.state[group[0].field]?.value || "",
          progress: progress++,
        };

        if (typeof this.state.exam.id !== "undefined") {
          const examQuestionObj = this.state.examQuestion;
          const examQuestionArray = Object.keys(examQuestionObj).map(
            (key) => examQuestionObj[key]
          );
          data.id = examQuestionArray.filter(
            (item) => item.field === group[0].field
          )[0]?.id;
        }

        payload.examQuestion.push(data);
      }
    });

    //--- handle draft mode ---
    if (typeof this.state.exam.id === "undefined") {
      payload.exam = {
        registrationCode: this.state.rCode,
        status: "draft",
      };
      this.saveRemote(payload);
    } else {
      payload.exam = { ...this.state.exam };

      payload.examQuestion = payload.examQuestion.map((item) => {
        const existingItem = this.state.examQuestion.find(
          (eq) => eq.field === item.field
        );

        if (existingItem) {
          if (item.answer !== "") {
            existingItem.answer = item.answer;
          } else {
            item.answer = existingItem.answer;
          }
        }

        return item;
      });

      this.setState({
        loading: false,
        loadingError: false,
      });

      this.updateRemote(payload);
    }

    setTimeout(() => {
      this.loadRemoteExam(this.state.rCode);
    }, 1000);
  };

  /** */
  saveRemote(payload) {
    examService
      .examInsert(payload)
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
          showSuccess: true,
          exam: { ...data.data.payload.resExam },
          examQuestion: { ...data.data.payload.resExamQuestion },
        });

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 4000);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  updateRemote(payload) {
    examService
      .examUpdate(payload)
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
          showSuccess: true,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 4000);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  confirmExam = (e, value) => {
    ModalHandler.show(
      value,
      "Conferma Elaborato",
      "Vuoi confermare l'invio dell'elaborato? La conferma dell'elaborato non potrà essere più modificata.",
      null,
      this.onConfirm
    );
  }; //deleteRecord

  onConfirm = (value) => {
    this.setState({
      loading: true,
      loadingError: false,
    });

    let isValidForm = this.checkValidation();
    if (!isValidForm) {
      this.setState({ loading: false });
      return true;
    }

    this.onSubmit();

    examService
      .confirmRC3060AllExamWritten({ ...this.state.exam, status: "confirmed" })
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
        });

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 1000);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  };

  //---
  downloadPDF = () => {
    const { rCode } = this.state;
    this.setState({ loading: true });

    examService
      .downloadPdfExamWritten(rCode)
      .then(({ data }) => {
        let loadingError = "";

        if (data.payload && data.payload.udaPayload) {
          window.open(data.payload.udaPayload.location, "_blank");
        } else {
          loadingError =
            "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. ";
        }

        this.setState({ loading: false, loadingError });
      })
      .catch((error) => {
        console.log(error);
        const requestError =
          "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: " +
          networkErrorHelper.getErrorMessage(error);
        this.setState({ loading: false, loadingError: requestError });
      });
  };

  canEdit = (dateExam) => {

    // la prova si può svolgere fino a 5 giorni prima della data fissata
    // entro le  ore 20:00
    const dayLeft = dateHelper.dayLeft(dateExam);
    if (dayLeft > this.DAYS_DEADLINE) {
      return true;
    } else if (dayLeft === this.DAYS_DEADLINE) {
      const minuteLeft = dateHelper.minuteLeft(this.HOUR_DEADLINE);
      if (minuteLeft > 0) {
        return true;
      }
    }

    return false;
  };

  renderEdit() {
    const { defaultValues, showSuccess, exam, examRevision } = this.state;
    let isPending = false;

    if (exam && exam.status === "revisionPending") {
      // TODO SAV: l'uda è in stato di revisione, dopo 24 ore dalla nota di revisinoe dovrebbe andare in sola lettura!
      isPending = true;
    }

    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <div className="container">
          {showSuccess && (
            <div className="Container">
              <BoxSuccess className="mt-4">
                Elaborato salvato con successo!
              </BoxSuccess>
            </div>
          )}
          {isPending && (
            <MnemoCallout className="mnemo-callout-danger w-100">
              <h5 className="text-red">ATTENZIONE: ELABORATO NON IDONEO</h5>
              <p>
                L'elaborato non risulta idonea. La invitiamo a modificare l'elaborato, nel
                rispetto delle motivazioni di seguito riportate, e premere il
                tasto{" "}
                <b>
                  Conferma Elaborato<b></b>
                </b>
              </p>
              <p>
                {" "}
                <i>{examRevision.revisionNote}</i>
              </p>
            </MnemoCallout>
          )}
          <form>
            {code}

            <Row className="my-5">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                isFormActive={true}
                buttonText="Salva Modifiche"
              />

              {parseInt(exam.id) > 0 && (
                <Button
                  color="primary bg-dark"
                  onClick={() => {
                    this.confirmExam();
                  }}>
                  Consegna Elaborato
                </Button>
              )}
            </Row>
            <Row>
              <Col xs="12">
                <MnemoCallout title="Note per la compilazione">
                  <hr />
                  <ul>
                    <li>
                      Le modifiche correnti saranno salvate solo premendo il
                      tasto <b>Salva Modifiche</b>. Si possono apportare diverse
                      modifiche memorizzandole tramite il tasto{" "}
                      <b>Salva Modifiche</b> per memorizzare le modifiche
                      apportate.
                    </li>
                    <li>
                      Le modifiche salvate verranno memorizzate e sarà possibile
                      continuare la compilazione dell'elaborato anche in un secondo
                      momento.
                    </li>
                    <li>
                      Premendo il tasto <b>Consegna Elaborato</b> l'elaborato verrà
                      inviato e non potrà più essere modificato. Potrà solo
                      essere consultato e scaricato fino al giorno dell'esame.
                    </li>
                    <li>
                      In caso di non idoneità dell'elaborato riceverà un avviso con i
                      motivi specifici affinché possa, tempestivamente,
                      procedere ad apportare le modifiche necessarie per rendere
                      l'elaborato idoneo alla valutazione da parte della Commissione.
                    </li>
                    <li>
                      In caso di mancata consegna, entro i termini previsti
                      tramite il tasto <b>Consegna Elaborato</b> il sistema acquisirà
                      autonomamente e senza ulteriore avviso il lavoro svolto
                      fino a quel momento.
                    </li>
                  </ul>
                </MnemoCallout>
              </Col>
            </Row>
          </form>
        </div>
      </>
    );
  }

  renderRead() {
    const { examQuestion } = this.state;

    return (
      <>
        <BoxSuccess className="mt-4">
          Elaborato consegnato. Non è possibile modificare i dati.
          <Button
            onClick={this.downloadPDF}
            color="primary bg-dark"
            className="mx-5">
            Scarica PDF
          </Button>
        </BoxSuccess>
        {this.FIELDS_GROUP.map((rowFields, i) => {
          return rowFields.map((field, j) => {
            if (field.for) {
              const question = examQuestion.filter(
                (item) => item.field === field.for
              )[0];

              return (
                <Row key={j}>
                  <MnemoCallout
                    className="mnemo-callout-info w-100"
                    title={field.label}>
                    <p>
                      <i>{ReactHtmlParser(field.infoText)}</i>
                    </p>
                    <hr />
                    <p>
                      {question && (
                        <>
                          {question.field === "examSubject" ? (
                            <>{this.state.examSubjectLabel}</>
                          ) : (
                            <>{question.answer}</>
                          )}
                        </>
                      )}
                    </p>
                  </MnemoCallout>
                </Row>
              );
            }
          });
        })}
      </>
    );
  }

  render() {
    const { loading, loadingError, rCode, exam } = this.state;

    if (loading) return <MnemoLoading></MnemoLoading>;

    if (loadingError) {
      return (
        <BoxDanger className="mt-4">
          Si è verificato un errore durante il caricamento della pagina,
          <br />
          <SupportoTecnico />
        </BoxDanger>
      );
    }

    return (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" />
          TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        <Title>Prova scritta</Title>
        <hr />
        {exam && (
          <>
            {exam.status === "confirmed" || exam.status === "revisioned" ? (
              <>{this.renderRead()}</>
            ) : (
              <>{this.renderEdit()}</>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(RC60CFUALL1UDAExamWritten);
