import { ROUTES } from "const";
import { CS24 } from "./cs24";
import { CSU24 } from "./csu24";
import { M24 } from "./m24";
import { MUMC24 } from "./mumc24";
import { ESB } from "./esb";
import { ESBSE } from "./esbse";
import { ICDL } from "./icdl";
import { INFO } from "./info";
import { CV } from "./cv";
import { CV2 } from "./cv2";
import { DS2 } from "./ds2";
import { EMOZIONI } from "./emozioni";
import { PR } from "./pr";
import { MU } from "./mu";
import { BU2 } from "./bu2";
import { PFU } from "./pfu";
import { courseService } from "libs";
import { MNE } from "./mne";
import { MNESE } from "./mnese";
import { RCPFU } from "./rcpfu";
import { UCSU } from "./ucsu";
import { RCSU } from "./rcsu";
import { RC30 } from "./rc30";
import { UM30 } from "./um30";
import { CM30 } from "./cm30";
import { TFA } from "./tfa";
import { RCCFUALL } from "./rccfuall";

export class CoursePlugin {


    static async getDashboardCards(course, userCourse) {

        const registrationCode = userCourse.registrationCode;

        let cards = [];
        let notices = [];

        if (course.metadata) {

            if (course.metadata.dashboard && course.metadata.dashboard.length > 0) {

                switch (course.metadata.dashboard) {

                    case 'MU':
                        cards = await MU.getDashboardCards(registrationCode);
                        notices = MU.getDashboardNotices();
                        break;

                    case 'ESB':
                        cards = ESB.getDashboardCards();
                        break;

                    case 'ESBSE':
                        cards = ESBSE.getDashboardCards();
                        break;

                    case 'MNE':
                        cards = await MNE.getDashboardCards(registrationCode);
                        break;

                    case 'MNESE':
                        cards = MNESE.getDashboardCards();
                        break;

                    case 'ICDL':
                        cards = ICDL.getDashboardCards();
                        break;

                    case 'CS24':
                        cards = await CS24.getDashboardCards(registrationCode);
                        notices = CS24.getDashboardNotices();
                        break;

                    case 'CSU24':
                        cards = await CSU24.getDashboardCards(registrationCode);
                        notices = CSU24.getDashboardNotices();
                        break;

                    case 'UCSU':
                        cards = await UCSU.getDashboardCards(registrationCode);
                        notices = UCSU.getDashboardNotices();
                        break;

                    case 'RCSU':
                        cards = await RCSU.getDashboardCards(registrationCode);
                        notices = RCSU.getDashboardNotices();
                        break;

                    case 'M24':
                        cards = await M24.getDashboardCards(registrationCode);
                        notices = M24.getDashboardNotices();
                        break;

                    case 'MUMC24':
                        cards = await MUMC24.getDashboardCards(registrationCode);
                        notices = MUMC24.getDashboardNotices();
                        break;

                    case 'INFO':
                        cards = INFO.getDashboardCards();
                        break;

                    case 'CV':
                        cards = CV.getDashboardCards();
                        break;

                    case 'CV2':
                        cards = CV2.getDashboardCards();
                        break;

                    case 'PR':
                        cards = PR.getDashboardCards();
                        break;

                    case 'DS2':
                        cards = await DS2.getDashboardCards(registrationCode);
                        break;

                    case 'BU2':
                        cards = await BU2.getDashboardCards(registrationCode);
                        notices = BU2.getDashboardNotices();
                        break;

                    case 'EMOZIONI':
                        cards = EMOZIONI.getDashboardCards();
                        break;

                    case 'PFU':
                        cards = await PFU.getDashboardCards(course.code, registrationCode);
                        notices = PFU.getDashboardNotices(course.code);
                        break;

                    case 'RCPFU':
                        cards = await RCPFU.getDashboardCards(course.code, registrationCode);
                        notices = RCPFU.getDashboardNotices(course.code);
                        break;

                    case 'RC30':
                        cards = await RC30.getDashboardCards(registrationCode, userCourse.idExamSession);
                        notices = RC30.getDashboardNotices(userCourse.idExamSession);
                        break;

                    case 'UM30':
                        cards = await UM30.getDashboardCards(registrationCode, userCourse.idExamSession);
                        notices = UM30.getDashboardNotices(userCourse.idExamSession);
                        break;

                    case 'CM30':
                        cards = await CM30.getDashboardCards(registrationCode, userCourse.idExamSession);
                        notices = CM30.getDashboardNotices(userCourse.idExamSession);
                        break;

                    case 'TFA':
                        cards = await TFA.getDashboardCards(registrationCode);
                        notices = await TFA.getDashboardNotices(registrationCode);
                        break;

                    case 'RCCFUALL':
                        cards = await RCCFUALL.getDashboardCards(course.code, registrationCode);
                        notices = await RCCFUALL.getDashboardNotices(course.code, userCourse.pluginConfig.competitionClass);
                        break;

                    default:
                        cards = this.getDefault();
                }
            }
        }

        if (cards.length <= 0) {

            try {
                cards = this.getFixedCards();
                const result = await courseService.perfezionamentoActiveLink(registrationCode);
                if (result.data.payload) {
                    const { payload } = result.data;
                    if (payload.examReservationEnabled === '1') {
                        cards.push({
                            title: 'Prenotazione Esami',
                            link: ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION, // ROUTES.COURSE_EXAM_WRITTEN_RESERVATION,
                            img: '/img/icon/badge.png',
                            className: 'image-box'
                        });
                    }
                    if (payload.writtenEnabled === '1') {
                        cards.push({
                            title: 'Prova di Profitto', link: ROUTES.PERFEZIONAMENTO_EXAM_WRITTEN,
                            img: '/img/icon/badge.png', className: 'image-box'
                        });
                    }
                    if (payload.liveReservationEnabled === '1') {
                        cards.push({
                            title: 'Prenotazione Esami Orali',
                            link: ROUTES.COURSE_EXAM_LIVE_RESERVATION,
                            img: '/img/icon/8.png', className: 'image-box'
                        });
                    }
                    if (payload.isLinkExamEnabled === '1') {
                        cards.push({
                            title: 'Avvio di Google Meet',
                            link: ROUTES.PERFEZIONAMENTO_EXAM_LIVE,
                            img: '/img/icon/call.png', className: 'image-box'
                        });
                    }
                    if (payload.titleRequestEnabled === '1') {
                        cards.push({
                            title: 'Richiesta Certificazione e Pergamena',
                            link: ROUTES.COURSE_TITLE_REQUEST,
                            img: '/img/icon/graduate.png', className: 'image-box'
                        });
                    }
                    /*
                                        RICHIESTA DA FRANESCO in DATA 03/10/2022 via skype
                                        if (payload.titleSelfEnabled === '1') {
                                            cards.push({
                                                title: 'Piano di Studi',
                                                link: ROUTES.COURSE_TITLE_SELF_DECLARATION,
                                                img: '/img/icon/graduate.png', className: 'image-box'
                                            });
                                        }
                    */
                }
            } catch (error) {

            }
        }

        return { cards, notices };
    }

    static async getDefault() {
        const cards = this.getFixedCards();
        return { cards, notices: [] };
    }

    static getFixedCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Invio materiale', link: ROUTES.LEARNING_FINAL_PAPER,
                img: '/img/icon/document.png', className: 'image-box'
            }, {
                title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Bando e Regolamenti', link: ROUTES.COURSE_DOCS,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Tutor didattico', link: ROUTES.COURSE_TUTOR,
                img: '/img/icon/call.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            }, {
                title: 'Progetto Corso Gratuito', link: ROUTES.COURSE_PROVIDER,
                img: '/img/icon/euro2.png', className: 'image-box'
            }
        ];
    }
}