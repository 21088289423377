
import { BoxDanger, ErrorNotification, MnemoLoading, MSection, SupportoTecnico, Title } from "components/misc";
import { ROUTES } from "const";
import { Button, Icon } from "design-react-kit";
import { networkErrorHelper, titleRequestService } from "libs";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';

class MUUM30TitleDownload extends Component {

    state = {
        titleRequest: null,
        titleRequestGOMP: null,
        user: null,
        userProfile: null,
        userCourse: null,
        loading: true,
        loadingGOMP: false,
        ladingError: false,
        requestError: '',
        rCode: '',
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {
        // TODO: implement this method
        this.setState({rCode, loading: false });
    }

    downloadTitle = () => {
        const { rCode} = this.state;

        this.setState({ loading: true });
        titleRequestService.downloadTitleUM30(rCode)
            .then(({ data }) => {

                if (data.payload) {  
                    
                    const { titleRequestMNEMO, titleRequestGOMP } = data.payload;

                    this.setState({                       
                        titleRequestMNEMO: titleRequestMNEMO,
                        titleRequestGOMP,
                        loading: false,
                        loadingError: '',                        
                    });

                    if (titleRequestGOMP.jsonResponse) {

                        const jsonResponse = JSON.parse(titleRequestGOMP.jsonResponse);
                        const requestResult = `data:application/pdf;base64,${jsonResponse.certificateFile.$value}`;
                        const filename = 'conseguimentoTitoloConEsami_' + rCode + '.pdf';

                        let a = document.createElement('a');
                        a.href = requestResult;
                        a.download = filename;
                        a.click();           
                        this.setState({ requestError: '', request: true });         
                    } else {
                        const requestError = "Si è verificato un errore durante l'elaborazione della richiesta. Contattare il supporto tecnico. Dettagli errore: "
                            + data.payload.errors;
                        ErrorNotification.render(requestError, 'errore');                        
                    }
                }

                this.setState({ loading: false });
            })
            .catch(error => {
                const requestError = "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: " + networkErrorHelper.getErrorMessage(error);
                this.setState({ loading: false, requestError, requestResult: '', request: true });
            });
    }

    render() {

        const { loading, loadingError, requestError, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Download Certificato Finale</Title>
            <MSection>
                {(loadingError)
                    ? <BoxDanger className="mt-4">
                        Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi <SupportoTecnico />
                        {(requestError.length > 0) && (<p>Codice Errore: {requestError}</p>)}
                    </BoxDanger>
                    : (<>                        
                        <p>Per effettuare il download cliccare su "Scarica certificato"</p>
                        <Button color="primary" onClick={this.downloadTitle}>Scarica certificato</Button>
                    </>)
                }

            </MSection>
        </>);
    }
}

export default withRouter(MUUM30TitleDownload);