import { mnemoService, proxyApiMnemo } from '.';
import {proxyBE} from './common';

export const titleRequestService = {    
   
    getPendingRequest(orderNumber){
        return proxyBE.get(`titleRequest/pending-request/${orderNumber}`);
    },
  
    updateRequestStep(payload){
        return proxyBE.put('titleRequest/updateStep', payload);        
    },

    prepareSign(requestId){
        return proxyBE.put('titleRequest/prepare-sign', {requestId});
    },

    postSign(payload){
        return proxyBE.put('titleRequest/post-sign', payload);
    },

    getRequestMNEMO(orderNumber) {
        const payload = mnemoService.buildPayload('getRequest', 'registrationCode', orderNumber);
        return proxyApiMnemo.get('titleRequest/?payload='+encodeURI(payload));
    },     

    downloadTitle(registrationCode, requestID){
        const payload = mnemoService.buildPayloadObject('downloadTitle', {registrationCode, requestID });
        return proxyApiMnemo.get('titleRequest/?payload='+encodeURI(payload));
    },

    downloadTitleUM30(registrationCode)
    {
        const payload = mnemoService.buildPayload('downloadTitleUM30', "registrationCode", registrationCode);
        return proxyApiMnemo.get('titleRequest/?payload='+encodeURI(payload));
    },

    downloadCertificate(registrationCode, requestID){
        const payload = mnemoService.buildPayloadObject('downloadTitle', {registrationCode, requestID });
        return proxyApiMnemo.get('certificationRequest/?payload='+encodeURI(payload));
    }
}